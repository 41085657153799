import {
  Component,
  ErrorInfo,
  FunctionComponent,
  isValidElement,
  PropsWithChildren,
  PropsWithRef,
  ReactElement,
} from 'react';

interface SharedProps {
  errorLogger: (error: Error, errorInfo: ErrorInfo) => void;
}

interface ErrorBoundaryPropsWithComponent extends SharedProps {
  FallbackComponent?: React.ComponentType<{ error: Maybe<Error> }>;
  fallback?: never;
}

interface ErrorBoundaryPropsWithFallback extends SharedProps {
  FallbackComponent?: never;
  fallback?: ReactElement<
    unknown,
    string | FunctionComponent | typeof Component
  > | null;
}

type Props = ErrorBoundaryPropsWithComponent | ErrorBoundaryPropsWithFallback;

interface State {
  error: Maybe<Error>;
}

class ErrorBoundary extends Component<
  PropsWithRef<PropsWithChildren<Props>>,
  State
> {
  public override state: State = {
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { errorLogger } = this.props;
    errorLogger(error, errorInfo);
  }

  public override render() {
    const { children, fallback, FallbackComponent } = this.props;

    if (this.state.error) {
      if (isValidElement(fallback)) {
        return fallback;
      } else if (FallbackComponent) {
        return <FallbackComponent error={this.state.error} />;
      }

      return null;
    }

    return children;
  }
}

export default ErrorBoundary;
