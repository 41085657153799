import { useGetFooterConfigQuery } from '@codegen/cmsUtils';
import { useConstants } from '@context/ConstantContext';

const usePartnerFooter = () => {
  const { locale, partner } = useConstants();
  const { data: footerData } = useGetFooterConfigQuery(
    { partner, locale },
    {
      keepPreviousData: true,
    },
  );

  return footerData?.partner?.footerConfig;
};

export default usePartnerFooter;
