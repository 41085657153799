import { ThemeV4Fragment } from '@codegen/cmsUtils';
import { ThemeBorder } from '@ui-v2/types/borders';

// Utility file located in ui-v2/style utils
// Cannot import the function due to circular references
const hexToRGBA = (h: string, opacity: number) => {
  let hex = h.startsWith('#') ? h.slice(1) : h;

  if (hex.length === 3) {
    hex = Array.from(hex).reduce((str, x) => str + x + x, '');
  }
  const values = hex
    .split(/([a-z0-9]{2,2})/)
    .filter(Boolean)
    .map((x) => parseInt(x, 16));

  return `rgba${values.length === 4 ? 'a' : ''}(${values.join(
    ', ',
  )}, ${opacity})`;
};

export const buildBorderVariants = (theme: ThemeV4Fragment): ThemeBorder => ({
  critical: `1px solid ${theme.colours.borderCritical}`,
  default: `1px solid ${theme.colours.borderDefault}`,
  information: `1px solid ${theme.colours.borderInformation}`,
  'information-subdued': `1px solid ${hexToRGBA(
    theme.colours.borderInformation,
    0.2,
  )}`,
  interactive: `1px solid ${theme.colours.borderInteractive}`,
  success: `1px solid ${theme.colours.borderSuccess}`,
  warning: `1px solid ${theme.colours.borderWarning}`,
  inverse: `1px solid ${theme.colours.borderInverse}`,
  strong: `1px solid ${theme.colours.borderStrong}`,
  subdued: `1px solid ${theme.colours.borderSubdued}`,
  logo: `1px solid ${theme.colours.logoBorder}`,
  transparent: 'transparent',
  none: 'none',
});
