import { match } from 'ts-pattern';
import { PassengerTitlesFragment } from '@codegen/cmsUtils';
import { PaxGender } from '@codegen/offerAPI';
import { Partner, Language } from '@shared/types/enums';

export const mapPartnerToDefaultLocale = (hostName?: string | null) => {
  switch (true) {
    case hostName?.includes(Partner.Avianca):
      return Language.Spanish;
    case hostName?.includes(Partner.AirTransat):
      return Language.French;
    default:
      return Language.English;
  }
};

export const mapHostNameToRoute = ({
  partner,
  route,
}: {
  partner?: Partner | null;
  route: string;
}) =>
  match([
    partner,
    route,
    process.env.NEXT_PUBLIC_ENABLE_NEW_FRONT_PAGE,
  ] as const)
    // Here we can map routes to partners, example:
    // .with([Partner.Easyjet, '/', 'true'], () => '/easyjet')
    .otherwise(() => '');

export const passengerTitlesWithGenderType = (
  passengerTitles: PassengerTitlesFragment[],
) => {
  return passengerTitles.map((titles) => {
    return {
      ...titles,
      options: titles.options.map((option) => {
        return {
          ...option,
          gender: option.gender as PaxGender,
        };
      }),
    };
  });
};
