import * as Sentry from '@sentry/nextjs';
import { ImageFragment } from '@codegen/cmsUtils';
import Box from '@ui-v2/core/Box/Box';
import Image from '@ui-v2/core/Image/Image';
import LoadingShimmer from '../LoadingShimmer/LoadingShimmer';

export interface Props {
  className?: string;
  iconHeight?: number;
  isLoading?: boolean;
  logo?: Maybe<ImageFragment>;
  vendorName?: string;
}

const ICON_ASPECT_RATIO = 2.5;

const CarrierLogo = ({
  className,
  iconHeight = 30,
  isLoading,
  logo,
  vendorName,
}: Props) => {
  if (isLoading) {
    return (
      <Box
        aria-busy
        className={className}
        height={iconHeight}
        position="relative"
        widthCustom={iconHeight * ICON_ASPECT_RATIO}
      >
        <LoadingShimmer />
      </Box>
    );
  }

  if (!logo) {
    if (vendorName) {
      Sentry.captureException(
        new Error(`Missing logo for vendor: ${vendorName}`),
      );
    }

    return null;
  }

  return (
    <Box
      alignItems="center"
      borderRadius="cardSecondary"
      className={className}
      display="flex"
      height={iconHeight}
      position="relative"
    >
      <Image
        alt={logo.alt || `${vendorName} logo`}
        blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        data-cy="offer-header-carrier-logo"
        height={iconHeight}
        objectFit="contain"
        placeholder="blur"
        src={logo.url}
        width={iconHeight * ICON_ASPECT_RATIO}
      />
    </Box>
  );
};

export default CarrierLogo;
