import { css } from '@emotion/react';

const airFranceFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Excellence in Motion Screen';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/AirFrance/Typography_ExcellenceInMotion_Bold.woff2')
      format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Excellence in Motion Screen';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/AirFrance/Typography_Excellence_In_Motion_Medium_V5.woff2')
      format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Excellence in Motion V4';
    font-style: normal;
    font-weight: 200;
    src: url('/fonts/AirFrance/Typography_ExcellenceInMotion_Regular.woff2')
      format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Excellence in Motion V4';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/AirFrance/Typography_ExcellenceInMotion_Thin.woff2')
      format('woff2');
  }
`;

export default airFranceFonts;
