import { ThemeV4Fragment } from '@codegen/cmsUtils';
import { ThemeShapes } from '@ui-v2/types/borders';

export const buildShapes = (theme: ThemeV4Fragment): ThemeShapes => {
  const { shapeV4 } = theme;

  return {
    ...shapeV4,
    none: 0,
  };
};
