import TrustPilotWidget from '../TrustPilotWidget/TrustPilotWidget';

interface Props {
  className?: string;
  identifier: string;
  localeString: string;
}

const IframeWrapper = ({ className, identifier, localeString }: Props) => {
  switch (identifier) {
    case 'trustpilot-widget':
      return (
        <TrustPilotWidget
          className={className}
          localeString={localeString}
          widgetType="starter"
        />
      );
    default:
      return null;
  }
};

export default IframeWrapper;
