import styled from '@emotion/styled';
import { mqPrint } from '@ui-v2/utils/styleUtils';
import Box from '../Box/Box';

const StyledContainerBox = styled(Box)`
  ${mqPrint} {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
`;

type Props = {
  children?: React.ReactNode;
};

const Container = (props: Props) => {
  return (
    <StyledContainerBox
      maxWidth="container"
      mx={['auto']}
      px={[16, 16, 24, 24, 24, 24, 0]}
      width="viewportWidth"
      {...props}
    />
  );
};

export default Container;
