import { ServiceConfigFragmentFragment } from '@codegen/cmsUtils';
import { AdditionalLuggageServiceClass, ServiceClass } from '@codegen/offerAPI';

export type ServiceClassNameMapper = Record<
  AdditionalLuggageServiceClass,
  string
>;

export const createServiceClassNameMapper = ({
  serviceConfig,
}: {
  serviceConfig: ServiceConfigFragmentFragment | null;
}): ServiceClassNameMapper => ({
  [ServiceClass.luggage_cabin]: serviceConfig?.luggageCabin?.name.value ?? '',
  [ServiceClass.luggage_checked]:
    serviceConfig?.luggageChecked?.name.value ?? '',
  [ServiceClass.luggage_personal]:
    serviceConfig?.luggagePersonal?.name.value ?? '',
});
