import { Passenger } from '@codegen/offerAPI';
import useCmsTranslation from '@hooks/useCmsTranslation';
import { getPassengerFullName } from '@ui/utils/passengerUtils';
import Box from '@ui-v2/core/Box/Box';
import Modal from '@ui-v2/core/Modal/Modal';
import OneButtonModalFooter from '@ui-v2/core/Modal/OneButtonModalFooter';
import Text from '@ui-v2/core/Text/Text';
import useGetOffer from '@web/context/hooks/useGetOffer';
import { getIatasFromSummary } from '@web/utils/booking/summaryUtils';
import AlignedLuggageAllowance from '../../Booking/AlignedLuggage/AlignedPassengerLuggage/AlignedLuggageAllowance';
import PassengerLuggageSummary from '../../Booking/Summary/SummaryPassengers/Luggage/PassengerLuggageSummary';

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  passenger: Passenger;
};

const AlignedLuggageBreakdownModal = ({
  isOpen,
  onCloseModal,
  passenger,
}: Props) => {
  const { t } = useCmsTranslation();
  const { offer } = useGetOffer();
  const passengerFullName = getPassengerFullName(passenger);

  const iatas = getIatasFromSummary(offer?.summary);

  return (
    <Modal
      footer={
        <OneButtonModalFooter
          onOpenChange={onCloseModal}
          text={t('Close', 'Close')}
        />
      }
      header={
        <Box>
          <Text as="h3" variant="heading-5">
            {t('baggage', 'Luggage')}
          </Text>
          <Text as="div" colour="text.subdued" variant="body-2">
            {t(
              'luggage_overview_for_passenger',
              `Overview for ${passengerFullName}`,
              { name: passengerFullName },
            )}
          </Text>
        </Box>
      }
      id="aligned-luggage-breakdown-modal"
      isOpen={isOpen}
      onOpenChange={onCloseModal}
    >
      {offer && (
        <PassengerLuggageSummary
          hideHeaderSection
          passenger={passenger}
          summary={offer.summary}
        />
      )}

      <Box mt={20}>
        <AlignedLuggageAllowance iatas={iatas} />
      </Box>
    </Modal>
  );
};

export default AlignedLuggageBreakdownModal;
