import { css } from '@emotion/react';

const frenchbeeFonts = css`
  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/FrenchBee/Roboto-Light.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/FrenchBee/Roboto-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/FrenchBee/Roboto-Bold.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Trade Gothic LT Std';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/FrenchBee/TradeGothicLTStd.woff2');
  }
`;

export default frenchbeeFonts;
