// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import {
  IllustrationConfigFragment,
  ImageWithConfigFragment,
  useDohopConnectQuery,
  useGetPartnerQuery,
} from '@codegen/cmsUtils';
import { Language } from '@codegen/gatewayUtils';
import { dohopConnectQueryPlaceholderData } from '@utils/queryUtils';
import { mapHostNameToPartner } from './useGetIcon';

// We try to get the illustration through the partners illustrationConfig, if the partner doesn't have one we use the dohop one
const useGetIllustration = () => {
  const partner = mapHostNameToPartner(
    typeof window !== 'undefined' ? window.location.hostname : undefined,
  );

  const { data: partnerData } = useGetPartnerQuery(
    {
      partner,
    },
    { retry: 2 },
  );

  const { locale } = useRouter();

  const { data: dohopData } = useDohopConnectQuery(
    { locale: locale as Language },
    {
      keepPreviousData: true,
      placeholderData: dohopConnectQueryPlaceholderData,
    },
  );

  const illustrationConfig = partnerData?.partner?.illustrationConfig;
  const dohopIllustrationConfig = dohopData?.dohopConnect?.illustrationConfig;

  const getIllustration = (
    illustrationIdentifier: keyof IllustrationConfigFragment,
  ) => {
    if (
      (!illustrationConfig && !dohopIllustrationConfig) ||
      (!illustrationConfig?.[illustrationIdentifier] &&
        !dohopIllustrationConfig?.[illustrationIdentifier])
    ) {
      return null;
    }

    return (illustrationConfig?.[illustrationIdentifier] ||
      dohopIllustrationConfig?.[
        illustrationIdentifier
      ]) as ImageWithConfigFragment;
  };

  return getIllustration;
};

export default useGetIllustration;
