import Text from '../../Text/Text';

const InputError = ({
  children,
  id,
}: {
  children?: React.ReactNode;
  id?: string;
}) => (
  <Text
    as="div"
    colour="text.critical"
    id={`${id}-error`}
    mt={4}
    variant="body-2"
  >
    {children}
  </Text>
);

export default InputError;
