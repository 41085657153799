import { RUNTIME_ENV } from '@shared/types/enums';

export const getUIDataProps = (props: Record<string, unknown>) => {
  // Ignore dev tools on production
  if (process.env['NEXT_PUBLIC_RUNTIME_ENV'] === RUNTIME_ENV.production) {
    return {};
  }

  return {
    'data-bg': 'bg' in props ? props.bg : undefined,
    'data-colour': 'colour' in props ? props.colour : undefined,
    'data-box-shadow': 'boxShadow' in props ? props.boxShadow : undefined,
    'data-border': 'border' in props ? props.border : undefined,
  };
};
