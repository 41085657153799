import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { resetList } from '../../styles/base';

const UnorderedList = styled.ul(({ theme: { colours } }) => [
  resetList,
  css`
    list-style-type: disc;

    li::marker {
      color: ${colours.brand.accent};
    }
  `,
]);

export default UnorderedList;
