import Axios, { AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: '/api/perses/offer',
});

export const useCustomInstance = <T>(): ((
  config: AxiosRequestConfig,
) => Promise<T>) => {
  return (config: AxiosRequestConfig) => {
    const source = Axios.CancelToken.source();
    // Waits 200ms before sending the request to see if duplicate requests are made
    const promise = new Promise<T>((resolve, reject) => {
      AXIOS_INSTANCE({
        ...config,
        cancelToken: source.token,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });

    // @ts-expect-error Taken from orval example
    // eslint-disable-next-line functional/immutable-data
    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };
};

export default useCustomInstance;
