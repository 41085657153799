export enum RUDDERSTACK_DESTINATIONS {
  FacebookPixel = 'Facebook Pixel',
  GoogleAds = 'Google Ads',
  Google_Analytics_4 = 'Google Analytics 4',
  MixPanel = 'Mixpanel',
}

interface apiObject {
  [index: string]:
    | string
    | number
    | boolean
    | apiObject
    | (string | number | boolean | apiObject)[]
    | undefined;
}

interface integrationOptions {
  // Destination name: true/false/integration specific information
  [index: string]: boolean | undefined | apiObject;
  // Defaults to true
  // If set to false, specific integration should be set to true to send the event
  All?: boolean;
}

interface apiOptions {
  // Merged with event's contextual information
  [index: string]:
    | string
    | number
    | boolean
    | apiObject
    | (string | number | boolean | apiObject)[]
    | integrationOptions
    | undefined;
  anonymousId?: string;
  integrations?: integrationOptions;
  // ISO 8601 date string
  originalTimestamp?: string;
}

declare global {
  interface Window {
    eventQueue: (() => void)[];
    rudderanalytics?: {
      getAnonymousId: () => string;
      getUserId: () => string;
      identify: (
        userId?: string | object,
        userProperties?: object,
        options?: apiOptions,
      ) => void;
      page: (
        pageName: string,
        eventProperties: object,
        options?: apiOptions,
      ) => void;
      track: (
        eventName: string,
        eventProperties: object,
        options?: apiOptions,
      ) => void;
    };
  }
}

// We need to map over the currently supported rudderstack destinations to check whether users have constented
// The values need to match exatcly the values that Usercentrics pushed to the data-layer
export const createConsentedDestinations = () => {
  if (window.dataLayer) {
    return Object.values(RUDDERSTACK_DESTINATIONS).reduce(
      (acc, destination) =>
        window.dataLayer &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.dataLayer.some((data: any) => data[destination as string])
          ? {
              ...acc,
              [destination]: true,
            }
          : acc,
      { All: false },
    );
  }

  return {
    All: false,
  };
};
