import { ReactNode } from 'react';
import { ContentFragment } from '@codegen/cmsUtils';
import useCmsTranslation from '@hooks/useCmsTranslation';
import { Language } from '@shared/types/enums';
import Modal from '@ui-v2/core/Modal/Modal';
import OneButtonModalFooter from '@ui-v2/core/Modal/OneButtonModalFooter';
import DefaultErrorBoundary from '../ErrorBoundary/DefaultErrorBoundary';
import LoadingShimmer from '../LoadingShimmer/LoadingShimmer';
import StandaloneContent from './StandaloneContent';

interface Props {
  children?: ReactNode;
  className?: string;
  content?: ContentFragment[];
  height?: string;
  id: string;
  isOpen: boolean;
  locale: Language;
  residency: string;
  title?: string;
  toggleModal: () => void;
}

const StandaloneContentModal = ({
  children,
  content,
  id,
  isOpen,
  locale,
  residency,
  title,
  toggleModal,
}: Props) => {
  const { t } = useCmsTranslation();

  return (
    <Modal
      footer={
        <OneButtonModalFooter
          onOpenChange={toggleModal}
          text={t('Close', 'Close')}
        />
      }
      id={id}
      isOpen={isOpen}
      onOpenChange={toggleModal}
      title={title}
    >
      <DefaultErrorBoundary
        text={t(
          'Something went wrong when fetching this content',
          'Something went wrong when fetching this content',
        )}
      >
        {content ? (
          <>
            <StandaloneContent
              content={content}
              locale={locale}
              residency={residency}
            />
            {children}
          </>
        ) : (
          <LoadingShimmer />
        )}
      </DefaultErrorBoundary>
    </Modal>
  );
};

export default StandaloneContentModal;
