import { useCountriesQuery } from '@codegen/gatewayUtils';
import { useConstants } from '@context/ConstantContext';
import useBookingConfig from './useBookingConfig';

export const useCountries = () => {
  const { locale } = useConstants();
  const { countryBlocklist } = useBookingConfig();

  const { data } = useCountriesQuery(
    {
      language: locale,
    },
    { placeholderData: { countries: [] }, retry: 2 },
  );

  const countries = data?.countries ?? [];

  // Some of our partners come from countries that do not acknowledge certain
  // nations and thus prefer not to showcase them on their platform.
  // That is why we filter them out.

  const filteredCountries = countries.filter((country) => {
    return !countryBlocklist.some(
      (blockedCountry) => blockedCountry.id === country.id,
    );
  });

  return filteredCountries;
};
