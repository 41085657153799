import { Vendors } from '@codegen/cmsTypes';
import { BookingStepFragment } from '@codegen/cmsUtils';
import { RUNTIME_ENV } from '@shared/types/enums';

export const shouldShowSeatmapForVendors = (vendors: Vendors) => {
  return vendors.some(
    (vendor) =>
      vendor.vendorBookingConfig?.seatsConfig &&
      !vendor.vendorBookingConfig.seatsConfig.environmentBlacklist?.includes(
        process.env.NEXT_PUBLIC_RUNTIME_ENV ?? RUNTIME_ENV.dev,
      ),
  );
};

export const getShownSteps = (
  steps: BookingStepFragment[],
  stepToRemove: Array<string>,
): BookingStepFragment[] => {
  return steps.filter((step) => !stepToRemove.includes(step.route));
};
