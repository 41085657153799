import {
  LegGroupSummaryAdditionalServicesItem,
  LegGroupSummaryIncludedServicesItem,
  Passenger,
  ServiceClass,
  Summary,
} from '@codegen/offerAPI';

export type PassengerServices = {
  additional_services: LegGroupSummaryAdditionalServicesItem[];
  included_services: LegGroupSummaryIncludedServicesItem[];
};

export type PassengerServicesRecord = Record<string, PassengerServices>;

export type PassengerServiceClass =
  | LegGroupSummaryIncludedServicesItem['service_class']
  | LegGroupSummaryAdditionalServicesItem['service_class'];

// Combine the services from all of this passengers legs into a single record
export const getPassengerServices = ({
  passengerId,
  summary,
}: {
  passengerId: string;
  summary?: Summary;
}): PassengerServices => ({
  additional_services:
    summary?.leg_summaries.flatMap((leg) => {
      return leg.additional_services.filter(
        (x) => x.passenger_id === passengerId,
      );
    }) ?? [],
  included_services:
    summary?.leg_summaries.flatMap((leg) => leg.included_services) ?? [],
});

export const combinePassengerSummaries = (
  passengerServices: PassengerServices | null,
) => {
  const includedServiceClasses = Array.from(
    new Set(
      passengerServices?.included_services.map(
        (service) => service.service_class,
      ),
    ),
  );

  const additionalServiceClasses = Array.from(
    new Set(
      passengerServices?.additional_services.map(
        (service) => service.service_class,
      ),
    ),
  );

  // Combine all services for this passenger
  const serviceClasses: PassengerServiceClass[] = [
    ...includedServiceClasses,
    ...additionalServiceClasses,
  ];

  return {
    serviceClasses,
  };
};

export const getLegIdsFromSummary = (summary: Summary) =>
  summary.leg_summaries.flatMap((leg) => leg.legs).map((x) => x.leg_id);

// Create a record of all services per passenger
// We use this to render the services icons in the passenger summary trigger
export const createPassengerServicesRecord = ({
  passengers,
  summary,
}: {
  passengers: Passenger[];
  summary?: Summary;
}) =>
  passengers.reduce<PassengerServicesRecord>((prev, curr) => {
    return {
      ...prev,
      [curr.passenger_id]: {
        ...getPassengerServices({
          summary,
          passengerId: curr.passenger_id,
        }),
      },
    };
  }, {});

export const seatServiceClasses: string[] = [
  ServiceClass['seat_reservation'],
  ServiceClass['seat_selection'],
  ServiceClass['seat_selection_any'],
  ServiceClass['seat_selection_economy'],
  ServiceClass['seat_selection_emergency_exit'],
  ServiceClass['seat_selection_extra_legroom'],
  ServiceClass['seat_selection_first_row'],
  ServiceClass['seat_selection_mismatch'],
  ServiceClass['seat_selection_plus'],
  ServiceClass['seat_selection_standard'],
  ServiceClass['seat_selection_standard_upfront'],
  ServiceClass['seat_selection_upfront'],
];
