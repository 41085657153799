import { css } from '@emotion/react';

const norseFonts = css`
  @font-face {
    font-display: fallback;
    font-family: Inter;
    src: url('/fonts/Norse/Inter-Regular.woff2');
  }
`;

export default norseFonts;
