import React, { Fragment } from 'react';
import Trans from 'next-translate/Trans';
import { Vendors } from '@codegen/cmsTypes';
import useCmsTranslation from '@hooks/useCmsTranslation';
import Box from '@ui-v2/core/Box/Box';
import Icon from '@ui-v2/core/Icon/Icon';
import Link from '@ui-v2/core/Link/Link';
import Text from '@ui-v2/core/Text/Text';
import useVendors from '@web/context/hooks/useVendors';

const LuggageAllowanceLinks = ({ vendors }: { vendors: Vendors }) => {
  const { t } = useCmsTranslation();

  return (
    <span>
      {vendors.map(
        (vendor, index) =>
          vendor.baggageAllowanceLink?.link && (
            <Fragment key={vendor.id}>
              <Link
                href={vendor.baggageAllowanceLink.link}
                size="small"
                target="_blank"
              >
                {vendor.name}
              </Link>
              {vendors.length > 2 && index < vendors.length - 2 && (
                <span>, </span>
              )}
              {index === vendors.length - 2 && <span> {t('and', 'and')} </span>}
            </Fragment>
          ),
      )}
    </span>
  );
};

const AlignedLuggageAllowance = ({
  iatas,
  subtext,
}: {
  iatas: string[];
  subtext?: React.ReactNode;
}) => {
  const { vendors } = useVendors({ iatas });

  return (
    <Box alignItems="center" display="flex" gap={12}>
      <Box flex="0 0 auto">
        <Icon size={24} type="luggageInfoIcon" />
      </Box>

      <Box>
        <Text as="div" variant="body-2">
          <Trans
            components={[<LuggageAllowanceLinks vendors={vendors} />]}
            i18nKey="aligned_luggage_allowance_label"
          />
        </Text>
        {subtext ? subtext : null}
      </Box>
    </Box>
  );
};

export default AlignedLuggageAllowance;
