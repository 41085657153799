import { css } from '@emotion/react';

const philippineairlinesFonts = css`
  @font-face {
    font-display: fallback;
    font-family: Montserrat;
    src: url('/fonts/philippineairlines/Montserrat-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: Montserrat-Bold;
    src: url('/fonts/philippineairlines/Montserrat-Bold.woff2');
  }
`;

export default philippineairlinesFonts;
