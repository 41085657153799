import * as Sentry from '@sentry/nextjs';
import { RUNTIME_ENV } from '@shared/types/enums';

const SENTRY_DSN =
  process.env['SENTRY_DSN'] || process.env['NEXT_PUBLIC_SENTRY_DSN'];

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.0,
    enableTracing: false,
    maxValueLength: 10000,
    environment:
      process.env['NEXT_PUBLIC_RUNTIME_ENV'] === RUNTIME_ENV.production
        ? 'production'
        : 'development',
  });
}
