import { DeeplinkBreakdownItem, TierName } from '@codegen/offerAPI';
import { CurrencyCode } from '@shared/types/enums';
import { parseQueryString } from '@utils/queryUtils';

export type FlightTotalType = { currency: CurrencyCode; total: number };

export const getDeeplinkTotalForFlights = (
  breakdown?: DeeplinkBreakdownItem[],
) => {
  return breakdown?.reduce<FlightTotalType>(
    (accumulator, booking: DeeplinkBreakdownItem) => {
      if (booking.booking_type === 'travel') {
        return {
          total: accumulator.total + booking.price.amount,
          currency: booking.price.currency as CurrencyCode,
        };
      }

      return accumulator;
    },
    { total: 0, currency: CurrencyCode.EUR },
  );
};

export const getTierIdFromDeeplink = ({
  fares,
  hasMultipleTiers,
  home,
  out,
}: {
  fares: string | string[] | undefined;
  hasMultipleTiers: boolean;
  home: string | string[] | undefined;
  out: string | string[] | undefined;
}): TierName => {
  if (!fares) {
    return 'premium';
  }

  const isOutDirect = parseQueryString(out).split('---').length === 1;
  const isHomeDirect = parseQueryString(home).split('---').length === 1;

  if (!isOutDirect || !isHomeDirect) {
    if (hasMultipleTiers) {
      return 'base';
    }

    return 'premium';
  }

  const vendors = new Set(
    parseQueryString(fares)
      .split('---')
      .map((fare) => fare.split('-')[2]),
  );

  if (vendors.size === 1) {
    return 'commission';
  }

  return 'premium';
};
