'use client';

import { createContext, ReactNode, useContext } from 'react';
import {
  IconConfigFragment,
  ImageWithConfigFragment,
  useDohopConnectQuery,
  useGetPartnerQuery,
} from '@codegen/cmsUtils';
import { dohopConnectQueryPlaceholderData } from '@utils/queryUtils';
import { useConstants } from './ConstantContext';

interface IconConfigData {
  getIcon: (
    iconIdentifier: keyof IconConfigFragment,
  ) => ImageWithConfigFragment | null;
  iconConfig: IconConfigFragment | null;
}

const context = createContext<IconConfigData>({
  iconConfig: null,
  getIcon: () => null,
});

const IconConfigContext = ({ children }: { children: ReactNode }) => {
  const { locale, partner } = useConstants();
  const { data: partnerData } = useGetPartnerQuery(
    {
      partner,
    },
    { retry: 2 },
  );

  const { data: dohopData } = useDohopConnectQuery(
    { locale },
    {
      keepPreviousData: true,
      placeholderData: dohopConnectQueryPlaceholderData,
    },
  );

  const iconConfig = partnerData?.partner?.iconConfig;
  const dohopIconConfig = dohopData?.dohopConnect?.iconConfig;

  const getIcon = (iconIdentifier: keyof IconConfigFragment) => {
    if (
      (!iconConfig && !dohopIconConfig) ||
      (!iconConfig?.[iconIdentifier] && !dohopIconConfig?.[iconIdentifier])
    ) {
      return null;
    }

    return (iconConfig?.[iconIdentifier] ||
      dohopIconConfig?.[iconIdentifier]) as ImageWithConfigFragment;
  };

  return (
    <context.Provider
      value={{
        iconConfig:
          partnerData?.partner?.iconConfig ||
          dohopData?.dohopConnect?.iconConfig ||
          null,
        getIcon,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default IconConfigContext;

export const useGetIconConfig = () => useContext(context);
