import { useApplicationStringsV2Query } from '@codegen/gatewayUtils';
import { useConstants } from '@context/ConstantContext';
import { parseApplicationStrings } from '@utils/applicationStringUtils';

const useApplicationStrings = (namespaces: string[]) => {
  const { locale } = useConstants();
  const { data, isLoading } = useApplicationStringsV2Query({
    namespaces,
    locale,
  });

  return {
    i18nDict: parseApplicationStrings(data),
    isLoading,
  };
};

export default useApplicationStrings;
