import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';
import Box from '@ui-v2/core/Box/Box';
import StructuredTextWrapper from './StructuredTextWrapper';

interface Props {
  className?: string;
  data?: Maybe<StructuredTextType>;
}

const StructuredParagraphWrapper = ({ className, data }: Props) => {
  return (
    <Box className={className}>
      <StructuredTextWrapper data={data} />
    </Box>
  );
};

export default StructuredParagraphWrapper;
