import { Passenger, PaxType } from '@codegen/offerAPI';
import { TranslateCmsString } from '@hooks/useCmsTranslation';
import { IconType } from '@ui-v2/types/typography';

export const getPassengerFullName = (passenger: Passenger) => {
  return `${passenger.first_name || ''} ${passenger.last_name || ''}`.trim();
};

export const getPassengerTypeIndex = (
  passengers: Passenger[],
  passenger: Passenger,
) => {
  const index = passengers
    .filter((pax) => pax.expected_type === passenger.expected_type)
    .findIndex((pax) => passenger.passenger_id === pax.passenger_id);

  return index + 1;
};

export const getPassengerTitleWithFallback = ({
  passenger,
  passengers,
  t,
}: {
  passenger: Passenger;
  passengers: Passenger[];
  t: TranslateCmsString;
}) => {
  const fullName = getPassengerFullName(passenger);
  const passengerTypeIndex = getPassengerTypeIndex(passengers, passenger);

  return (
    fullName ||
    `${t(
      passenger.expected_type,
      passenger.expected_type,
    )} ${passengerTypeIndex}`
  );
};

export const passengerTypeIconMapper: Record<PaxType, IconType> = {
  [PaxType.a]: 'passengerIcon',
  [PaxType.c]: 'passengerChildIcon',
  [PaxType.i]: 'passengerInfantIcon',
};
