import { css } from '@emotion/react';

const airTransatFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Apercu Pro';
    src: url('/fonts/AirTransat/ApercuPro-Regular.woff2');
  }
`;

export default airTransatFonts;
