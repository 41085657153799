import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useCmsTranslation from '@hooks/useCmsTranslation';
import { CurrencyCode } from '@shared/types/enums';
import Icon from '@ui-v2/core/Icon/Icon';
import { setCursorOnHover } from '@ui-v2/utils/styleUtils';
import { resetButton } from '../../styles/cssReset';

export interface Props {
  color?: string;
  currency: CurrencyCode;
  isOpen: boolean;
  language: string;
  toggleSettings: () => void;
}

const SettingsButton = styled.button([
  resetButton,
  setCursorOnHover,
  css`
    height: 24px;
  `,
]);

const SettingsDisplay = ({
  currency,
  isOpen,
  language,
  toggleSettings,
}: Props) => {
  const { t } = useCmsTranslation();

  return (
    <SettingsButton
      aria-expanded={isOpen}
      aria-haspopup="menu"
      aria-label={`${t('Settings', 'Settings')} - ${language} (${currency})`}
      data-cy="settings-display"
      onClick={toggleSettings}
    >
      <Icon colour="components.header.text" size={24} type="globeIcon" />
    </SettingsButton>
  );
};

export default SettingsDisplay;
