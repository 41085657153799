import { Fragment } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StructuredText } from 'datocms-structured-text-utils';
import { useStandaloneContentQuery } from '@codegen/cmsUtils';
import useCmsTranslation from '@hooks/useCmsTranslation';
import { Language } from '@shared/types/enums';
import Modal from '@ui-v2/core/Modal/Modal';
import OneButtonModalFooter from '@ui-v2/core/Modal/OneButtonModalFooter';
import StructuredTextWrapper from '../Content/StructuredTextWrapper';

interface Props {
  id: string;
  isOpen: boolean;
  locale: Language;
  onCloseModal: () => void;
}

const StyledContent = styled.div(
  ({ theme }) => css`
    p {
      padding-bottom: ${theme.spacings[20]}px;
    }
  `,
);

const NoCheckedBagsModal = ({ id, isOpen, locale, onCloseModal }: Props) => {
  const { t } = useCmsTranslation();
  const { data } = useStandaloneContentQuery({
    identifier: 'no-checked-bags-journey-info',
    locale,
  });

  return (
    <Modal
      footer={
        <OneButtonModalFooter
          onOpenChange={onCloseModal}
          text={t('Close', 'Close')}
        />
      }
      id={id}
      isOpen={isOpen}
      onOpenChange={onCloseModal}
    >
      <StyledContent>
        {data?.allStandaloneContents.map((content, index) => (
          <Fragment key={`content-${index.toString()}`}>
            {content.content.map((item) =>
              item.__typename === 'StructuredParagraphRecord' ? (
                <StructuredTextWrapper
                  data={item.content?.content as Maybe<StructuredText>}
                  key={`${item.id}StructuredParagraph`}
                />
              ) : null,
            )}
          </Fragment>
        ))}
      </StyledContent>
    </Modal>
  );
};

export default NoCheckedBagsModal;
