import 'intl-pluralrules';
import 'focus-visible';
import 'resize-observer-polyfill';
import { useMemo } from 'react';
import { AppProps } from 'next/app';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConstantProvider } from '@context/ConstantContext';
import IconConfigProvider from '@context/IconConfigContext';
import { Partner } from '@shared/types/enums';
import DevOnly from '@ui-v2/components/DevOnly/DevOnly';
import GridOverlay from '@ui-v2/core/Grid/GridOverlay';
import { defaultReactQueryClientOptions } from '@utils/queryUtils';
import HeadTags from '@web/components/HeadTags';
import { ModalOrchestratorContextProvider } from '@web/components/ModalOrchestratorContext';
import PageLayout from '@web/components/PageLayout';
import { AnalyticsProvider } from '@web/context/AnalyticsContext';
import ApplicationStringProvider from '@web/context/ApplicationStringProvider';
import { ErrorOrchestratorContextProvider } from '@web/context/ErrorOrchestratorContext';
import ExperimentProvider from '@web/context/ExperimentContext';
import useRoutingIndicator from '@web/context/hooks/useRoutingIndicator';
import { SettingsProvider } from '@web/context/SettingsContext';
import { StepProvider } from '@web/context/StepContext';
import { ThemeProvider } from '@web/context/ThemeContext';
import { Route } from '@web/types/enums';
import { isBookingRoute } from '@web/utils/booking/bookingUtils';
import { mapHostNameToPartner } from '@web/utils/hostnameUtils';

const DISABLED_CURRENCY_ROUTES = [
  Route.CreateOrder,
  Route.MyBooking,
  Route.Confirmation,
];

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const queryClient = useMemo(
    () => new QueryClient({ defaultOptions: defaultReactQueryClientOptions }),
    [],
  );

  const {
    locale,
    query: {
      currency: queryCurrency,
      partner = pageProps.partner ||
        mapHostNameToPartner(
          typeof window !== 'undefined' ? window.location.hostname : undefined,
        ),
      residency: queryResidency,
    },
    route,
  } = useRouter();

  useRoutingIndicator();

  if (
    route.includes(Route.NotFound) ||
    route.includes('.xml') ||
    route.includes('.txt')
  ) {
    return (
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} queryClient={queryClient} />
      </QueryClientProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <ConstantProvider locale={locale} partner={partner as Partner}>
          <SettingsProvider
            isDisabledCurrencyConversion={DISABLED_CURRENCY_ROUTES.includes(
              route as Route,
            )}
            queryCurrency={queryCurrency}
            queryResidency={queryResidency}
          >
            <main className="app">
              <ExperimentProvider>
                <AnalyticsProvider>
                  <ApplicationStringProvider>
                    <ThemeProvider>
                      <IconConfigProvider>
                        <StepProvider>
                          <ErrorOrchestratorContextProvider>
                            <ModalOrchestratorContextProvider>
                              <HeadTags />
                              <PageLayout
                                hasMobileFooter={isBookingRoute(route)}
                              >
                                <Component {...pageProps} />
                                <DevOnly>
                                  <GridOverlay />
                                </DevOnly>
                              </PageLayout>
                            </ModalOrchestratorContextProvider>
                          </ErrorOrchestratorContextProvider>
                        </StepProvider>
                      </IconConfigProvider>
                    </ThemeProvider>
                  </ApplicationStringProvider>
                </AnalyticsProvider>
              </ExperimentProvider>

              <DevOnly devKey="NEXT_PUBLIC_USE_REACT_QUERY_DEVTOOLS">
                <ReactQueryDevtools initialIsOpen={false} />
              </DevOnly>
            </main>
          </SettingsProvider>
        </ConstantProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default CustomApp;
