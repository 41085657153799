import dayjs, { Dayjs } from 'dayjs';
import { BookingStepFragment } from '@codegen/cmsUtils';
import { wrapArrayIfNeeded } from '@utils/helperUtils';
import { Route } from '@web/types/enums';

export const constructSearchResultQueryPushParams = ({
  currency,
  departureDate,
  destinations,
  isOneWay,
  origins,
  paxTypeAges,
  residency,
  returnDate,
  steps,
  utmCampaign,
  utmMedium,
  utmSource,
}: {
  currency?: string | null;
  departureDate: Dayjs;
  destinations: string[];
  isOneWay: boolean;
  origins: string[];
  paxTypeAges: { [paxType: string]: number[] | number };
  residency?: string | null;
  returnDate: Dayjs | null;
  steps?: BookingStepFragment[];
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
}) => {
  const pathname = steps?.find((step) => step.isSearchStep)?.route;

  return {
    pathname: pathname ?? Route.Search,
    query: {
      origins: origins.join(','),
      destinations: destinations.join(','),
      departureDate: dayjs(departureDate).format('YYYY-MM-DD'),
      returnDate:
        isOneWay || !returnDate ? null : dayjs(returnDate).format('YYYY-MM-DD'),
      isOneWay: (isOneWay || !returnDate).toString(),
      currency: currency ? currency : null,
      residency: residency ? residency : null,
      utm_source: utmSource,
      utm_medium: utmMedium || '',
      utm_campaign: utmCampaign || '',
      ...Object.keys(paxTypeAges).reduce<{ [key: string]: string }>(
        (acc, paxType) =>
          paxTypeAges[paxType]
            ? {
                ...acc,
                [paxType]: (
                  wrapArrayIfNeeded(paxTypeAges[paxType]) as number[]
                ).join(','),
              }
            : acc,
        {},
      ),
    },
  };
};
