import { css } from '@emotion/react';

const tigerairtwFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Museo Sans Rounded';
    src: url('/fonts/tigerairtw/MuseoSansRounded-500.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: MuseoSansRounded-Bold;
    src: url('/fonts/tigerairtw/MuseoSansRounded-900.woff2');
  }
`;

export default tigerairtwFonts;
