import {
  AdditionalLuggage,
  IncludedLuggage,
  ServiceClass,
} from '@codegen/offerAPI';
import { luggageServiceClasses } from '@web/utils/booking/serviceUtils';
import { ServiceClassNameMapper } from './createServiceClassNameMapper';

export type GetServiceTitleService = AdditionalLuggage | IncludedLuggage;

const serviceIsBag = (
  service: GetServiceTitleService,
): service is AdditionalLuggage | IncludedLuggage =>
  luggageServiceClasses.includes(service.service_class);

export const getLuggageTitle = ({
  quantity,
  service_class,
  title,
  weight,
}: GetServiceTitleService & {
  title: string;
}) =>
  /**
   * Example formats:
   * Personal Item
   * 2x Underseat cabin bag
   * 23kg Checked bag
   * 2x 10kg Cabin bag
   * We intentionally don't show weights for personal items
   */
  `${quantity > 1 ? `${quantity}x ` : ''}${weight && service_class !== ServiceClass.luggage_personal ? `${weight}kg ` : ''}${title}`;

/**
 * Note: We only support luggage names at the moment
 */
export const getServiceTitle = ({
  service,
  serviceClassNameMapper,
}: {
  service: GetServiceTitleService;
  serviceClassNameMapper: ServiceClassNameMapper;
}) => {
  if (serviceIsBag(service)) {
    return getLuggageTitle({
      ...service,
      title: serviceClassNameMapper[service.service_class],
    });
  }

  // TODO: We only support luggage name at the moment
  return '';
};
