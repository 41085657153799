import { GetStaticPropsContext } from 'next/types';
import { QueryClient } from '@tanstack/react-query';
import {
  useGetPassengersConfigQuery,
  useGetFooterConfigQuery,
  useGetBookingConfigQuery,
  useGetItineraryConfigQuery,
  useGetPartnerExperimentQuery,
  useGetPartnersQuery,
  useGetServiceConfigQuery,
} from '@codegen/cmsUtils';
import { Partner, Language, RUNTIME_ENV } from '@shared/types/enums';
import { defaultReactQueryClientOptions } from '@utils/queryUtils';
import {
  getApplicationStrings,
  prefetchDohopConnect,
  prefetchHeaderConfig,
  prefetchPartnerConfig,
  prefetchUserSettingsConfig,
} from '@utils/sharedQueries';

export const DEFAULT_REVALIDATE_TIME =
  process.env.NEXT_PUBLIC_RUNTIME_ENV === RUNTIME_ENV.production
    ? 60 * 60 * 24
    : // For non production environments we want to revalidate as often as possible
      // This will cache the html for 5 seconds and serve it stale while we revalidate
      5;

export const prefetchBookingSteps = async ({
  locale,
  partner,
  queryClient,
}: {
  locale: Language;
  partner: string;
  queryClient: QueryClient;
}) =>
  await queryClient.prefetchQuery(
    useGetBookingConfigQuery.getKey({ partner, locale }),
    async () => await useGetBookingConfigQuery.fetcher({ partner, locale })(),
  );

export const prefetchServiceCofig = async ({
  locale,
  partner,
  queryClient,
}: {
  locale: Language;
  partner: string;
  queryClient: QueryClient;
}) =>
  await queryClient.prefetchQuery(
    useGetServiceConfigQuery.getKey({ partner, locale }),
    async () => await useGetServiceConfigQuery.fetcher({ partner, locale })(),
  );

export const fetchExperimentData = async ({
  locale,
  partner,
  queryClient,
}: {
  locale: Language;
  partner: string;
  queryClient: QueryClient;
}) => {
  await queryClient.prefetchQuery(
    useGetPartnerExperimentQuery.getKey({ partner, locale }),
    async () =>
      await useGetPartnerExperimentQuery.fetcher({ partner, locale })(),
    { staleTime: Infinity },
  );
};

export const doesNotRequireSecret = (route: string) => {
  return route === '/' || route === '/search';
};

export const defaultGetStaticProps = async (context: GetStaticPropsContext) => {
  const { locale: lang, params } = context;

  const partner = (params?.partner as Partner | undefined) || Partner.Dohop;

  const queryClient = new QueryClient({
    defaultOptions: defaultReactQueryClientOptions,
  });

  const locale = lang === 'default' ? Language.English : (lang as Language);

  await Promise.all([
    fetchExperimentData({
      queryClient,
      partner,
      locale,
    }),
    prefetchBookingSteps({ queryClient, partner, locale }),
    prefetchPartnerConfig({ partner, queryClient }),
    prefetchUserSettingsConfig({
      language: locale,
      queryClient,
    }),

    prefetchServiceCofig({ queryClient, partner, locale }),
    queryClient.prefetchQuery(
      useGetFooterConfigQuery.getKey({ partner, locale }),
      async () => await useGetFooterConfigQuery.fetcher({ partner, locale })(),
      { retry: 2 },
    ),
    prefetchHeaderConfig({ partner, queryClient, locale }),
    queryClient.prefetchQuery(
      useGetPassengersConfigQuery.getKey({ partner, locale }),
      async () =>
        await useGetPassengersConfigQuery.fetcher({ partner, locale })(),
      { retry: 2 },
    ),
    queryClient.prefetchQuery(
      useGetItineraryConfigQuery.getKey({ partner }),
      async () => await useGetItineraryConfigQuery.fetcher({ partner })(),
      { retry: 2 },
    ),
    prefetchDohopConnect({ locale, queryClient }),
    getApplicationStrings({
      partner,
      locale,
      queryClient,
    }),
  ]);

  return {
    queryClient,
  };
};

export const getPartnerPaths = async (
  runtimeEnvsToGeneratePathsFor = [RUNTIME_ENV.production],
) => {
  if (
    process.env.NEXT_PUBLIC_RUNTIME_ENV &&
    !runtimeEnvsToGeneratePathsFor.includes(
      process.env.NEXT_PUBLIC_RUNTIME_ENV as RUNTIME_ENV,
    )
  ) {
    return [];
  }

  const queryClient = new QueryClient({
    defaultOptions: defaultReactQueryClientOptions,
  });

  const data = await queryClient.fetchQuery(
    useGetPartnersQuery.getKey(),
    async () => await useGetPartnersQuery.fetcher()(),
    { retry: 2 },
  );

  const DISABLED_PARTNERS = [
    Partner.DohopConnect,
    Partner.Jetstar,
    Partner.Skyteam,
    Partner.TigerairTaiwan,
  ];

  return Object.values(Partner)
    .filter((partner) => !DISABLED_PARTNERS.includes(partner))
    .flatMap((partner) => {
      const partnerLocales =
        data.allPartners.find(
          (queryPartner) => (queryPartner.name as Partner) === partner,
        )?.userSettingsConfig?.supportedLanguages ?? [];

      return Object.values(Language)
        .filter((locale) => partnerLocales.find((lang) => lang.code === locale))
        .map((locale) => ({
          params: { partner },
          locale,
        }));
    });
};

export const defaultGetStaticPaths = async () => {
  const paths = await getPartnerPaths();

  return {
    paths,
    fallback: 'blocking', // false or "blocking"
  };
};
