import { OfferFragment } from '@codegen/gatewayUtils';
import {
  DohopServiceBase,
  OfferResponse,
  OfferResponseChargeTransactionsItem,
} from '@codegen/offerAPI';
import { RUNTIME_ENV } from '@shared/types/enums';
import { removeDuplicates } from '@utils/arrayUtils';
import { getIatasFromRoutes } from '@utils/itineraryUtils';

export const getIatasFromOffer = ({
  itinerary: { homebound, outbound },
}: OfferFragment) => {
  return removeDuplicates([
    ...getIatasFromRoutes(outbound),
    ...getIatasFromRoutes(homebound),
  ]);
};

export const getIatasFromOffers = (offers: OfferFragment[]) => {
  const iatas = offers.reduce<string[]>(
    (acc, offer) => [...acc, ...getIatasFromOffer(offer)],
    [],
  );

  return removeDuplicates(iatas);
};

export const modifyTransferUrl = ({
  newBookingFlowEnvironments,
  url,
  utmCampaign,
  utmMedium,
  utmSource,
}: {
  newBookingFlowEnvironments?: string[];
  url: string;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
}) => {
  const runtimeEnv = process.env.NEXT_PUBLIC_RUNTIME_ENV || RUNTIME_ENV.dev;

  if (typeof window === 'undefined' || !url) {
    return url;
  }

  try {
    const urlObj = new URL(url);

    if (!urlObj.searchParams.get('utm_source') && utmSource) {
      urlObj.searchParams.set('utm_source', utmSource);
    }

    if (!urlObj.searchParams.get('utm_medium') && utmMedium) {
      urlObj.searchParams.set('utm_medium', utmMedium);
    }

    if (!urlObj.searchParams.get('utm_campaign') && utmCampaign) {
      urlObj.searchParams.set('utm_campaign', utmCampaign);
    }

    if (runtimeEnv === RUNTIME_ENV.production) {
      return urlObj.toString();
    }

    const combo = urlObj.searchParams.get('combo');

    if (!combo || !newBookingFlowEnvironments?.includes(runtimeEnv)) {
      return urlObj.toString();
    }

    if (urlObj.pathname.includes('fares')) {
      return `${urlObj.pathname}?${urlObj.searchParams.toString()}`;
    }

    const language = urlObj.searchParams.get('lang');

    const newPathname = language ? `/${language}/fares` : '/fares';

    /* eslint-disable functional/immutable-data */
    urlObj.pathname = newPathname;
    urlObj.protocol = window.location.protocol;
    urlObj.host = window.location.host;
    /* eslint-enable functional/immutable-data */

    return urlObj.toString();
  } catch (error) {
    return url;
  }
};

export const getDohopServiceClassFromOfferSummary = (
  summary: OfferResponse['summary'] | undefined,
) =>
  summary?.other_services.find(
    (service): service is DohopServiceBase =>
      service.service_class === 'dohop_commission_fee' ||
      service.service_class === 'dohop_service_fee',
  )?.service_class ?? null;

export const chargeTransactionItemIsDohopConnectionService = (
  item: OfferResponseChargeTransactionsItem,
  dohopServiceClass?: 'dohop_commission_fee' | 'dohop_service_fee',
) => {
  const carrier = 'carrier' in item ? item.carrier : null;

  return !carrier && dohopServiceClass === 'dohop_service_fee';
};
