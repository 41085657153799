import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ButtonSize, ButtonVariant } from '@ui-v2/types/buttons';
import { ThemeMarginProps } from '@ui-v2/types/props';
import { IconType } from '@ui-v2/types/typography';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { buildMargin } from '@ui-v2/utils/themePropBuilders';
import Box from '../Box/Box';
import Icon from '../Icon/Icon';
import LoadingSpinner from '../Loaders/LoadingSpinner';
import {
  buildButtonIconConfig,
  buildButtonSize,
  buildButtonVariant,
  buildLoaderConfig,
} from './variants';

const TRANSITION_SPEED = '200ms';

export type ButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'ref'
> &
  ThemeMarginProps & {
    as?: 'button' | 'div' | 'span';
    icon?: IconType;
    iconLeft?: IconType;
    iconRight?: IconType;
    isLoading?: boolean;
    isRounded?: boolean;
    readOnly?: boolean;
    size?: ButtonSize;
    variant?: ButtonVariant;
    width?: 'full' | 'default';
  };

const StyledButton = styled('button')<
  ButtonProps & {
    size: NonNullable<ButtonProps['size']>;
    variant: NonNullable<ButtonProps['variant']>;
  }
>(
  ({
    isLoading,
    isRounded,
    readOnly,
    size,
    theme,
    variant,
    width,
    ...props
  }) => [
    // Non responsive values
    css`
      position: relative;
      display: inline-flex;
      overflow: hidden;
      height: max-content;
      flex: 0 0 auto;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      background: transparent;
      cursor: pointer;
      gap: ${theme.spacings[4]}px;
      transition:
        color ${TRANSITION_SPEED} ease,
        background-color ${TRANSITION_SPEED} ease,
        box-shadow ${TRANSITION_SPEED} ease,
        border-color ${TRANSITION_SPEED} ease,
        opacity ${TRANSITION_SPEED} ease;

      &:disabled {
        cursor: default;
        opacity: 0.5;
      }
    `,
    width === 'full' &&
      css`
        width: 100%;
      `,
    // Responsive values
    css(
      buildResponsiveValues({
        ...buildMargin(props),
        ...buildButtonSize(size, theme),
        ...buildButtonVariant(variant, theme),
      }),
    ),
    isRounded &&
      css`
        border-radius: 100%;
      `,
    readOnly &&
      css`
        pointer-events: none;
      `,
  ],
);

export const StyledIconWrapper = styled.div<{ ml?: number; mr?: number }>(
  ({ ml = 0, mr = 0 }) => css`
    flex: 0 0 auto;
    margin-right: ${mr}px;
    margin-left: ${ml}px;
  `,
);

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as,
      children,
      icon,
      iconLeft,
      iconRight,
      isLoading,
      size = 'regular',
      variant = 'primary',
      ...props
    },
    ref,
  ) => {
    const combinedIconLeft = icon ?? iconLeft;
    const isIconButton =
      size === 'iconRegular' ||
      size === 'iconSmall' ||
      size === 'iconExtraSmall';

    return (
      <StyledButton
        {...props}
        as={as}
        disabled={isLoading || props.disabled}
        ref={ref}
        size={size}
        variant={variant}
      >
        {combinedIconLeft && (
          <StyledIconWrapper
            ml={isIconButton || size === 'textRegular' ? 0 : -8}
          >
            <Icon
              type={combinedIconLeft}
              {...buildButtonIconConfig(size, variant)}
            />
          </StyledIconWrapper>
        )}
        {children}
        {iconRight && (
          <StyledIconWrapper
            mr={isIconButton || size === 'textRegular' ? 0 : -8}
          >
            <Icon type={iconRight} {...buildButtonIconConfig(size, variant)} />
          </StyledIconWrapper>
        )}
        {isLoading && (
          <Box
            alignItems="center"
            bg={buildLoaderConfig(variant).bg}
            bottom={0}
            className="button-loader-wrapper"
            display="flex"
            justifyContent="center"
            left={0}
            pointerEvents="none"
            position="absolute"
            right={0}
            top={0}
          >
            <LoadingSpinner
              className="button-loader"
              colour={buildLoaderConfig(variant).colour}
              diameter={20}
            />
          </Box>
        )}
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
