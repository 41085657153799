import { css } from '@emotion/react';

const jetstarFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Jetstar/helveticaneue.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Jetstar Happy';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Jetstar/JetstarHappyRegular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: JetstarHappyRegular;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Jetstar/JetstarHappyRegular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: JetstarHappyBold;
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/Jetstar/JetstarHappyBold.woff2');
  }
`;

export default jetstarFonts;
