import { VendorLinkFragment } from '@codegen/cmsUtils';
import { Language } from '@shared/types/enums';

export const getVendorLink = ({
  links,
  locale,
  residency,
}: {
  links?: VendorLinkFragment[];
  locale: Language;
  residency?: string;
}) => {
  if (!links || links.length === 0) {
    return;
  }
  const linkResBased = links.find(
    (link) => link.languageCode === `${locale}-${residency?.toUpperCase()}`,
  );

  const linkLocaleBased = links.find(
    (link) => link.languageCode === `${locale}`,
  );

  const linkFallback = links.find((link) => link.languageCode === `default`);

  const LinkEnglish = links.find(
    (link) => link.languageCode === Language.English,
  );

  return linkResBased || linkLocaleBased || linkFallback || LinkEnglish;
};
