import { Partner } from '@shared/types/enums';

export const mapHostNameToPartner = (hostName?: string | null) => {
  const possiblePartners = Object.values(Partner);
  const partner = possiblePartners.find((partner) =>
    hostName?.includes(partner),
  );

  return partner || Partner.Dohop;
};
