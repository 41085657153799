import React, { createContext, ReactNode, useContext } from 'react';

export type Analytics = {
  sendModalShownEvent: ({ id }: { id: string }) => void;
  sendPopupShownEvent: ({ id }: { id: string }) => void;
  sendTooltipShownEvent: ({ id }: { id: string }) => void;
};

const context = createContext<Analytics>({
  sendModalShownEvent: () => {},
  sendPopupShownEvent: () => {},
  sendTooltipShownEvent: () => {},
});

export default context;

export const BaseAnalyticsProvider = ({
  children,
  sendModalShownEvent,
  sendPopupShownEvent,
  sendTooltipShownEvent,
}: {
  children: ReactNode;
  sendModalShownEvent: Analytics['sendModalShownEvent'];
  sendPopupShownEvent: Analytics['sendPopupShownEvent'];
  sendTooltipShownEvent: Analytics['sendTooltipShownEvent'];
}) => {
  return (
    <context.Provider
      value={{
        sendModalShownEvent,
        sendPopupShownEvent,
        sendTooltipShownEvent,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useBaseAnalytics = () => useContext(context);
