import { CSSProperties, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useTrustPilotWidget from '../../hooks/useTrustPilotWidget';

interface Props {
  className?: string;
  height?: CSSProperties['height'];
  localeString: string;
  widgetType: 'mini' | 'starter';
}

const LoadingContainer = styled.div<{ height?: string }>(
  ({ height }) => css`
    width: 100%;
    height: ${height};
  `,
);

const TrustPilotWidget = ({
  className,
  height,
  localeString,
  widgetType,
}: Props) => {
  const trustBoxRef = useRef(null);
  const { isLoading } = useTrustPilotWidget();

  useEffect(() => {
    if (window.Trustpilot && !isLoading) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true);
    }
  }, [isLoading]);

  if (isLoading) {
    return <LoadingContainer />;
  }

  return (
    <div
      className={className}
      data-businessunit-id="4ea1223b000064000511506b"
      data-locale={localeString}
      data-style-height={height ?? '150px'}
      data-style-width="100%"
      data-template-id={
        widgetType === 'mini'
          ? '53aa8807dec7e10d38f59f32'
          : '5613c9cde69ddc09340c6beb'
      }
      data-theme="light"
      ref={trustBoxRef}
    >
      <a
        href="https://www.trustpilot.com/review/www.dohop.com"
        rel="noreferrer"
        target="_blank"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default TrustPilotWidget;
