import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Box, { BoxProps } from '../Box/Box';

const StyledListBox = styled(Box)(
  ({ theme }) => css`
    padding-left: ${theme.spacings[24]}px;
  `,
);

export const UL = (props: BoxProps) => {
  return <StyledListBox {...props} as="ul" />;
};

const StyledListItemBox = styled(Box)(
  ({ theme }) => css`
    &::marker {
      color: ${theme.colours.brand.primary};
    }
  `,
);

export const LI = (props: BoxProps) => {
  return <StyledListItemBox {...props} as="li" />;
};
