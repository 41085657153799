import { css } from '@emotion/react';

const oneworldFonts = css`
  @font-face {
    font-display: fallback;
    font-family: Roboto;
    src: url('/fonts/Oneworld/Roboto-Light.woff2');
  }
`;

export default oneworldFonts;
