import { Vendors } from '@codegen/cmsTypes';
import { VendorType } from '@shared/types/enums';
import { removeDuplicates } from '@utils/arrayUtils';

export const hasVendorType = (vendors: Vendors, vendorType: VendorType) =>
  Object.values(vendors).some((vendor) => vendor.vendorType === vendorType);

export const getIataStringPattern = (iatas: string[]) => {
  return removeDuplicates(iatas)
    .map((iata) => ',?' + iata + ',?')
    .join('|');
};

export const findVendorByIata = (vendors: Vendors, iata?: string) =>
  vendors.find((vendor) => iata && vendor.iata?.includes(iata));

export const filterVendorsByIatas = (vendors: Vendors, iatas?: string[]) => {
  if (!iatas) {
    return [];
  }

  return vendors
    .filter(
      (vendor) =>
        vendor.iata && iatas.some((iata) => vendor.iata?.includes(iata)),
    )
    .sort((firstVendor, secondVendor) =>
      iatas.findIndex((iata) => firstVendor.iata?.includes(iata)) >
      iatas.findIndex((iata) => secondVendor.iata?.includes(iata))
        ? 1
        : -1,
    );
};

export const getExcludedCardTypes = (vendors: Vendors) => [
  ...new Set(
    Object.values(vendors)
      .flatMap((vendor) => vendor.vendorBookingConfig?.excludedCardTypes || [])
      .filter(Boolean),
  ),
];
