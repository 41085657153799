import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ThemeFlexboxProps,
  ThemeMarginProps,
  ThemeOverflowProps,
} from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { mqMin, shouldForwardThemeProp } from '@ui-v2/utils/styleUtils';
import {
  buildMargin,
  buildOverflowProps,
  buildFlexProps,
} from '@ui-v2/utils/themePropBuilders';

export const standardGridGutter = {
  mobile: 16,
  tablet: 32,
  desktop: 32,
};

export const smallGridGutter = {
  mobile: 8,
  tablet: 12,
  desktop: 16,
};

export type GridGutterSize = 'standard' | 'small';

export type GridGutter = typeof standardGridGutter;

export const gutterSizeMapper: Record<GridGutterSize, GridGutter> = {
  small: smallGridGutter,
  standard: standardGridGutter,
};

type Props = Pick<ThemeOverflowProps, 'overflowX'> &
  ThemeMarginProps & {
    children?: React.ReactNode;
    gap?: ThemeFlexboxProps['gap'];
    justifyContent?: ThemeFlexboxProps['justifyContent'];
    spacing?: GridGutterSize;
  };

const StyledGridRow = styled('div', {
  shouldForwardProp: shouldForwardThemeProp,
})<
  Omit<Props, 'spacing'> & {
    gutter: GridGutter;
  }
>(({ gutter, theme, ...props }) => [
  css`
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    margin-right: -${gutter.mobile / 2}px;
    margin-left: -${gutter.mobile / 2}px;
    row-gap: ${gutter.mobile}px;

    ${mqMin[768]} {
      margin-right: -${gutter.tablet / 2}px;
      margin-left: -${gutter.tablet / 2}px;
      row-gap: ${gutter.tablet}px;
    }

    ${mqMin[1280]} {
      margin-right: -${gutter.desktop / 2}px;
      margin-left: -${gutter.desktop / 2}px;
      row-gap: ${gutter.desktop}px;
    }
  `,
  css(
    buildResponsiveValues({
      ...buildOverflowProps(props),
      ...buildMargin(props),
      ...buildFlexProps(props),
    }),
  ),
]);

const GridRow = ({
  overflowX = 'hidden',
  spacing = 'standard',
  ...props
}: Props) => (
  <StyledGridRow
    gutter={gutterSizeMapper[spacing]}
    overflowX={overflowX}
    {...props}
  />
);

export default GridRow;
