import styled from '@emotion/styled';
import { ImageFragment } from '@codegen/cmsUtils';
import { Leg } from '@codegen/offerAPI';
import useCmsTranslation from '@hooks/useCmsTranslation';
import Box from '@ui-v2/core/Box/Box';
import Image from '@ui-v2/core/Image/Image';
import Text from '@ui-v2/core/Text/Text';

const VendorImage = styled(Image)`
  width: auto;
  max-width: 90px;
  height: auto;
  max-height: 16px;
`;

type PassengerSummaryItemHeaderProps = {
  itemNumber: number;
  leg: Leg;
  numberOfLegs: number;
};

type PassengerSummaryItemProps = PassengerSummaryItemHeaderProps & {
  children?: React.ReactNode;
  vendorLogo?: ImageFragment;
};

// A small summary card, display a summary item header and a vendor logo if available
export const PassengerSummaryItem = ({
  children,
  itemNumber,
  leg,
  numberOfLegs,
  vendorLogo,
}: PassengerSummaryItemProps) => (
  <Box
    border="subdued"
    borderRadius="cardSecondary"
    display="flex"
    flexDirection="column"
    height="100%"
    px={8}
    py={12}
  >
    <PassengerSummaryItemHeader
      itemNumber={itemNumber}
      leg={leg}
      numberOfLegs={numberOfLegs}
    />

    <Box>{children}</Box>
    {vendorLogo && (
      <Box mt="auto">
        <Box
          alignItems="center"
          borderTop="subdued"
          display="flex"
          height={24}
          mt={16}
          pt={8}
        >
          <VendorImage
            alt={vendorLogo.alt}
            height={vendorLogo.height}
            src={vendorLogo.url}
            width={vendorLogo.width}
          />
        </Box>
      </Box>
    )}
  </Box>
);

// A summary item's header:
// London - Reykjavik
// Flight 1 of 4
export const PassengerSummaryItemHeader = ({
  itemNumber,
  leg,
  numberOfLegs,
}: PassengerSummaryItemHeaderProps) => {
  const { t } = useCmsTranslation();

  return (
    <>
      <Text as="div" colour="text.subdued" variant="body-3">
        {t('flight_index_of_total', `Flight ${itemNumber} of ${numberOfLegs}`, {
          index: itemNumber,
          total: numberOfLegs,
        })}
      </Text>
      <Text as="span" mb={12} variant="heading-6">
        {leg.origin.city_name} - {leg.destination.city_name}
      </Text>
    </>
  );
};
