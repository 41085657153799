'use client';

import { createContext, ReactNode, useContext } from 'react';
import { useGetPartnerQuery } from '@codegen/cmsUtils';
import { CurrencyFragment, useCurrenciesQuery } from '@codegen/gatewayUtils';
import { useConstants } from '@context/ConstantContext';
import { Language, CurrencyCode } from '@shared/types/enums';
import { parseQueryString } from '@utils/queryUtils';
import { getMainCurrencies } from '@web/utils/settingsUtils';

type Languages = { code: Language; name: string }[];

export type Settings = {
  currencies: CurrencyFragment[];
  currency: CurrencyCode;
  hideCurrencySelection?: Maybe<boolean>;
  hideResidencySelection?: Maybe<boolean>;
  languages: Languages;
  mainCurrencies: CurrencyFragment[];
  residency: string;
};

const DEFAULT_STATE: Settings = {
  currencies: [],
  mainCurrencies: [],
  languages: [],
  currency: CurrencyCode.EUR,
  residency: 'UK',
};

const context = createContext<Settings>(DEFAULT_STATE);

export default context;

export const SettingsProvider = ({
  children,
  isDisabledCurrencyConversion = false,
  queryCurrency,
  queryResidency,
}: {
  children: ReactNode;
  isDisabledCurrencyConversion?: boolean;
  queryCurrency?: string | string[];
  queryResidency?: string | string[];
}) => {
  const { locale, partner } = useConstants();

  const { data } = useGetPartnerQuery(
    {
      partner,
    },
    { retry: 2 },
  );

  const userSettingsConfig = data?.partner?.userSettingsConfig;

  const residency = queryResidency
    ? parseQueryString(queryResidency).toUpperCase()
    : (userSettingsConfig?.defaultResidency as string);

  const currency = (queryCurrency ||
    userSettingsConfig?.defaultCurrencyCode ||
    CurrencyCode.EUR) as CurrencyCode;

  const { data: currencyData } = useCurrenciesQuery(
    {
      language: locale,
    },
    { placeholderData: { currencies: [] }, retry: 2 },
  );

  const currencies = currencyData?.currencies ?? [];

  return (
    <context.Provider
      value={{
        currencies,
        languages: userSettingsConfig?.supportedLanguages as Languages,
        currency,
        residency,
        mainCurrencies: getMainCurrencies(
          currencies,
          userSettingsConfig?.mainCurrencies,
        ),
        hideCurrencySelection:
          userSettingsConfig?.hideCurrencySelection ||
          // Todo: Remove this check once we have an endpoint to do this on these pages
          isDisabledCurrencyConversion,
        hideResidencySelection: userSettingsConfig?.hideResidencySelection,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useSettings = () => useContext(context);
