import { Theme } from '@emotion/react';
import { IconColourProp } from '@ui-v2/types/props';
import { BadgeVariant } from './Badge';

type BadgeVariantStyleConfig = {
  background: string;
  color: string;
};

export const buildBadgeVariant = (
  variant: BadgeVariant,
  theme: Theme,
): BadgeVariantStyleConfig => {
  return {
    background: theme.colours.surface[variant],
    color: theme.colours.text.on[variant],
  };
};

type IconStyleConfig = {
  colour: IconColourProp;
};

export const buildBadgeIconConfig = (
  variant: BadgeVariant,
): IconStyleConfig => {
  return {
    colour: `icons.on.${variant}`,
  };
};
