import { useGetItineraryConfigQuery } from '@codegen/cmsUtils';
import { useConstants } from '@context/ConstantContext';

const useItineraryConfig = () => {
  const { partner } = useConstants();
  const { data } = useGetItineraryConfigQuery(
    {
      partner,
    },
    { retry: 2 },
  );

  return {
    showSelfConnectList:
      data?.partner?.itineraryConfig?.showSelfConnectList ?? true,
    showItineraryVendorLogos:
      data?.partner?.itineraryConfig?.showItineraryVendorLogos ?? false,
  };
};

export default useItineraryConfig;
