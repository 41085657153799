import { css } from '@emotion/react';

const airIndiaExpressFonts = css`
  @font-face {
    font-display: fallback;
    font-family: Montserrat;
    src: url('/fonts/AirIndiaExpress/Montserrat-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: Montserrat;
    font-weight: 700;
    src: url('/fonts/AirIndiaExpress/Montserrat-Bold.woff2');
  }
`;

export default airIndiaExpressFonts;
