import { Theme } from '@emotion/react';
import { TypographyV4Fragment } from '@codegen/cmsUtils';
import { ThemeResponsiveProp } from '@ui-v2/types/props';
import { TextVariant, TypographyVariant } from '@ui-v2/types/typography';
import { TextLineHeightProp, TextProps } from './Text';

export type TextVariantStyleConfig = {
  fontFamily: string;
  fontSize: ThemeResponsiveProp<number | string>;
  fontWeight: ThemeResponsiveProp<number>;
  letterSpacing?: ThemeResponsiveProp<number | string>;
  lineHeight: ThemeResponsiveProp<number | string | null | undefined>;
  textTransform?: string;
};

export const typographyVariantFragmentMapper: Record<
  TypographyVariant,
  keyof TypographyV4Fragment
> = {
  'body-1': 'body01',
  'body-2': 'body02',
  'body-3': 'body03',
  'display-1': 'display01',
  'display-2': 'display02',
  'display-3': 'display03',
  'heading-1': 'heading01',
  'heading-2': 'heading02',
  'heading-3': 'heading03',
  'heading-4': 'heading04',
  'heading-5': 'heading05',
  'heading-6': 'heading06',
  'heading-7': 'heading07',
};

/**
 * For a typography variant: maps it to it's corresponding responsive variant
 * Example: From widths 0 - 768px; Heading-1 has the font styles of Heading-2
 */
export const responsiveVariantMapper: Partial<
  Record<TypographyVariant, TypographyVariant>
> = {
  'heading-1': 'heading-2',
  'heading-2': 'heading-3',
  'heading-3': 'heading-4',
  'heading-4': 'heading-5',
};

export const buildTextVariant = (
  variant: TextVariant,
  theme: Theme,
  config: {
    lineHeight?: TextLineHeightProp;
    responsiveBehaviour: TextProps['responsiveBehaviour'];
  },
): TextVariantStyleConfig => {
  const fragment = typographyVariantFragmentMapper[variant];
  const responsiveVariant = responsiveVariantMapper[variant];
  const responsiveFragment = responsiveVariant
    ? typographyVariantFragmentMapper[responsiveVariant]
    : null;

  return {
    fontSize:
      responsiveFragment && config.responsiveBehaviour === 'scale-down'
        ? [
            theme.typography[responsiveFragment].fontSize,
            theme.typography[responsiveFragment].fontSize,
            theme.typography[fragment].fontSize,
          ]
        : theme.typography[fragment].fontSize,
    fontFamily: theme.typography[fragment].fontFamily,
    fontWeight: theme.typography[fragment].fontWeight,
    lineHeight:
      config.lineHeight ?? `${theme.typography[fragment].lineHeightPercent}%`,
    letterSpacing: theme.typography[fragment].letterSpacing,
    textTransform: theme.typography[fragment].textTransform,
  };
};
