import { ReactNode } from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { constructTheme } from '@ui-v2/theme/theme';
import usePartnerInfo from './hooks/usePartnerInfo';

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { themeV4 } = usePartnerInfo();

  return (
    <EmotionThemeProvider
      theme={constructTheme(themeV4 as NonNullable<typeof themeV4>)}
    >
      {children}
    </EmotionThemeProvider>
  );
};
