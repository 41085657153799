import { useGetHeaderConfigQuery } from '@codegen/cmsUtils';
import { useConstants } from '@context/ConstantContext';

const usePartnerHeader = () => {
  const { locale, partner } = useConstants();
  const { data } = useGetHeaderConfigQuery(
    { partner, locale },
    { keepPreviousData: true },
  );

  return data?.partner?.headerConfig;
};

export default usePartnerHeader;
