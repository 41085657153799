import {
  ServiceConfigFragmentFragment,
  useGetServiceConfigQuery,
} from '@codegen/cmsUtils';
import { useConstants } from '@context/ConstantContext';
import { createServiceClassNameMapper } from './createServiceClassNameMapper';
import {
  GetServiceTitleService,
  getServiceTitle as getServiceTitleFromMapper,
} from './getServiceTitle';

export const useServiceConfig = () => {
  const { locale } = useConstants();
  const { partner } = useConstants();
  const { data, isLoading } = useGetServiceConfigQuery({
    partner,
    locale,
  });

  /** Sets up a record of all services using the default config when the partner entry is not available */
  const config = data?.partnerConfig ?? data?.defaultConfig;
  const serviceConfig: ServiceConfigFragmentFragment | null =
    (data?.partnerConfig || data?.defaultConfig) && config
      ? Object.keys(config).reduce((prev, key) => {
          return {
            ...prev,
            [key]:
              data.partnerConfig?.[key as keyof typeof data.partnerConfig] ??
              data.defaultConfig?.[key as keyof typeof data.defaultConfig],
          };
        }, {})
      : null;

  const serviceClassNameMapper = createServiceClassNameMapper({
    serviceConfig,
  });

  /**
   * For a given service, uses the service config name and
   * parameters such as weight and quantity to return a formatted title for the service
   */
  const getServiceTitle = (service: GetServiceTitleService) =>
    getServiceTitleFromMapper({
      service,
      serviceClassNameMapper,
    });

  const baseTierName = serviceConfig?.base?.name.value ?? null;
  // This is a safe cast since the commission tier is always present in the default config
  const commissionTierName = serviceConfig?.commission?.name.value as string;
  // This is a safe cast since the premium tier is always present in the default config
  const premiumTierName = serviceConfig?.premium?.name.value as string;

  return {
    baseTierName,
    commissionTierName,
    premiumTierName,
    premiumTierId: serviceConfig?.premium?.serviceId as string,
    getServiceTitle,
    serviceClassNameMapper,
    isLoading,
  };
};
