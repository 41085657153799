import { ApplicationStringsV2Query } from '@codegen/gatewayUtils';

export const parseApplicationStrings = (data?: ApplicationStringsV2Query) => {
  const applicationStrings = data?.applicationStringsV2 ?? [];

  return applicationStrings.reduce<{ [key: string]: string }>(
    (acc, applicationString) => ({
      ...acc,
      [applicationString.stringId]: applicationString.value,
    }),
    {},
  );
};
