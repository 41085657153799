import { css } from '@emotion/react';

const aegeanFonts = css`
  @font-face {
    font-family: URWGeometricExt-Bold;
    src: url('/fonts/Aegean/URWGeometricExt-Bold.woff2');
  }

  @font-face {
    font-family: 'URW Geometric';
    src: url('/fonts/Aegean/URWGeometricExt-Regular.woff2');
  }
`;

export default aegeanFonts;
