import { useGetPartnerQuery } from '@codegen/cmsUtils';
import { useConstants } from '@context/ConstantContext';
import useCmsTranslation from '@hooks/useCmsTranslation';
import { getVendorLink } from '@utils/vendorUtils';
import { useSettings } from '../SettingsContext';
import useDohopConnect from './useDohopConnect';

const usePartnerInfo = () => {
  const { t } = useCmsTranslation();
  const { locale, partner } = useConstants();
  const { residency } = useSettings();

  const { data } = useGetPartnerQuery(
    {
      partner,
    },
    { retry: 2 },
  );

  const { supportEmail, supportLink } = useDohopConnect();

  const defaultSupportLinkLabel = t('Dohop help center', 'Dohop help center');
  const supportLinkInfo = getVendorLink({
    links: data?.partner?.helpCenter?.supportLink,
    locale,
    residency,
  });

  const helpCenter = data?.partner?.helpCenter
    ? {
        ...data.partner.helpCenter,
        supportLink: supportLinkInfo?.link ?? supportLink,
        supportLinkLabel: supportLinkInfo?.label ?? defaultSupportLinkLabel,
      }
    : {
        email: supportEmail,
        supportLink,
        supportLinkLabel: defaultSupportLinkLabel,
      };

  return {
    ...data?.partner,
    useCurrencyCode:
      data?.partner?.userSettingsConfig?.useCurrencyCode || false,
    useFloatingFraction:
      data?.partner?.userSettingsConfig?.useFloatingFraction || false,
    helpCenter,
  };
};

export default usePartnerInfo;
