import { css } from '@emotion/react';

const playFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Oli Grotesk L';
    font-style: normal;
    font-weight: 200;
    src: url('/fonts/play/OliGroteskL-Thin.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Oli Grotesk L';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/play/OliGroteskL-Light.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Oli Grotesk L';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/play/OliGroteskL-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Oli Grotesk L';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/play/OliGroteskL-Medium.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Oli Grotesk L';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/play/OliGroteskL-Bold.woff2');
  }
`;

export default playFonts;
