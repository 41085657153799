import { Dayjs } from 'dayjs';

export enum SearchQueryParams {
  CURRENCY = 'currency',
  DEPARTURE_DATE = 'departureDate',
  DESTINATIONS = 'destinations',
  IS_ONEWAY = 'isOneWay',
  ORIGINS = 'origins',
  PAX_TYPE_AGES_ADULT = 'adult',
  PAX_TYPE_AGES_CHILD = 'child',
  PAX_TYPE_AGES_INFANT = 'infant',
  RESIDENCY = 'residency',
  RETURN_DATE = 'returnDate',
}

export type SearchQueryParamsType = {
  [SearchQueryParams.ORIGINS]?: string[];
  [SearchQueryParams.DESTINATIONS]?: string[];
  [SearchQueryParams.DEPARTURE_DATE]?: string | null;
  [SearchQueryParams.RETURN_DATE]?: string | null;
  [SearchQueryParams.RESIDENCY]?: string;
  [SearchQueryParams.CURRENCY]?: string;
  [SearchQueryParams.IS_ONEWAY]?: string;
} & { [key: string]: string | null };

export type SearchQueryLocalStorageType = {
  [SearchQueryParams.ORIGINS]: string | null;
  [SearchQueryParams.DESTINATIONS]: string | null;
} & { [key: string]: string | null };

export enum ACTION_TYPE {
  ADD_PASSENGER,
  REMOVE_PASSENGER,
  UPDATE_AGE,
  UPATE_PAX_AGES,
  SELECT_DATES,
  SELECT_ORIGIN,
  SELECT_DESTINATION,
  TOGGLE_ONEWAY,
  RESET_STATE,
}

export type State = {
  [SearchQueryParams.ORIGINS]: string[];
  [SearchQueryParams.DESTINATIONS]: string[];
  [SearchQueryParams.DEPARTURE_DATE]: Maybe<Dayjs>;
  [SearchQueryParams.RETURN_DATE]: Maybe<Dayjs>;
  [SearchQueryParams.IS_ONEWAY]: boolean;
  paxTypeAges: { [key: string]: number[] };
};

export type Action =
  | {
      payload: { paxTypeAges: { [x: string]: number[] } };
      type: ACTION_TYPE.ADD_PASSENGER;
    }
  | {
      payload: { paxTypeAges: { [x: string]: number[] } };
      type: ACTION_TYPE.REMOVE_PASSENGER;
    }
  | {
      payload: { paxTypeAges: { [x: string]: number[] } };
      type: ACTION_TYPE.UPDATE_AGE;
    }
  | {
      payload: { paxTypeAges: { [x: string]: number[] } };
      type: ACTION_TYPE.UPATE_PAX_AGES;
    }
  | { payload: { dateRange: DateRange }; type: ACTION_TYPE.SELECT_DATES }
  | { payload: { origins: string[] }; type: ACTION_TYPE.SELECT_ORIGIN }
  | {
      payload: { destinations: string[] };
      type: ACTION_TYPE.SELECT_DESTINATION;
    }
  | { type: ACTION_TYPE.TOGGLE_ONEWAY }
  | {
      payload: { state: State };
      type: ACTION_TYPE.ADD_PASSENGER;
    }
  | {
      payload: { state: State };
      type: ACTION_TYPE.RESET_STATE;
    };

export interface Item {
  city?: string;
  code: string;
  isSelectable: boolean;
  rank: number;
  subItems: Item[];
  transportType?: string;
  value: string;
}
