import { useMemo } from 'react';
import { useGetItinerary } from '@codegen/offerAPI';
import { useConstants } from '@context/ConstantContext';
import { isOnClient } from '@utils/helperUtils';
import { parseMBSItinerary } from '@utils/mbsUtils';
import { parseQueryString } from '@utils/queryUtils';
import usePartnerRouter from './usePartnerRouter';

const DEFAULT_DATA = {
  outbound: [],
  homebound: [],
  covered_connections: [],
};

const useItinerary = () => {
  const { query } = usePartnerRouter();
  const { locale } = useConstants();

  const isEnabled = useMemo(
    () => isOnClient() && Boolean(query.combo),
    [query.combo],
  );

  const { data, isError, isFetching } = useGetItinerary(
    {
      combo: parseQueryString(query.combo),
      home: parseQueryString(query.home) || undefined,
      lang: locale,
      out: parseQueryString(query.out),
    },
    {
      query: {
        placeholderData: DEFAULT_DATA,
        retry: 2,
        enabled: isEnabled,
      },
    },
  );

  return {
    itinerary: useMemo(
      () => (data ? parseMBSItinerary(data) : DEFAULT_DATA),
      [data],
    ),
    isItineraryLoading: isFetching || !data || !isEnabled,
    isError,
  };
};

export default useItinerary;
