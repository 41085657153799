import { useCallback, useState } from 'react';

const useToggle = (defaultValue = false) => {
  const [isActive, setState] = useState(defaultValue);
  const toggle = useCallback(() => setState(!isActive), [isActive]);

  return [isActive, toggle] as const;
};

export default useToggle;
