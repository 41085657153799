/* eslint-disable functional/immutable-data */
import * as Sentry from '@sentry/nextjs';
import { RUNTIME_ENV } from '@shared/types/enums';
import Avo, { AvoEnv, CustomDestination, initAvo } from './Avo';
import { createConsentedDestinations } from './rudderstackUtils';

const getURLWithoutRedactedParams = (): string => {
  // Get the current URL
  const url = new URL(window.location.href);

  // Create a new searchParams without the unwanted params
  const searchParams = new URLSearchParams(
    Array.from(url.searchParams.entries()).filter(
      (param) => param[0] !== 'sig' && param[0] !== 'issued_at',
    ),
  );

  // Replace the searchParams in the original URL
  url.search = searchParams.toString();

  // Return the modified URL as a string
  return url.toString();
};

const rudderstackSDKBridge: CustomDestination = {
  make: () => {},
  logEvent: (eventName, eventProperties) => {
    const enhancedEventProperties = {
      ...eventProperties,
      redactedUrl: getURLWithoutRedactedParams(),
    };

    const event = () => {
      if (!window.rudderanalytics) {
        return;
      }

      window.rudderanalytics.track(
        eventName,
        enhancedEventProperties,
        createConsentedDestinations(),
      );
    };

    Sentry.addBreadcrumb({
      category: eventName,
      data: eventProperties,
      level: 'info',
    });

    if (!window.rudderanalytics) {
      return;
    }

    event();
  },
  logPage: (pageName, eventProperties) => {
    const enhancedEventProperties = {
      ...eventProperties,
      redactedUrl: getURLWithoutRedactedParams(),
    };

    const event = () => {
      if (!window.rudderanalytics) {
        return;
      }

      window.rudderanalytics.page(
        pageName,
        enhancedEventProperties,
        createConsentedDestinations(),
      );
    };

    Sentry.addBreadcrumb({
      category: 'page',
      data: eventProperties,
      level: 'info',
    });

    event();
  },
  revenue: (amount, eventProperties) => {
    const enhancedEventProperties = {
      ...eventProperties,
      redactedUrl: getURLWithoutRedactedParams(),
    };

    const event = () => {
      if (!window.rudderanalytics) {
        return;
      }

      window.rudderanalytics.track(
        'Order Completed',
        {
          total: amount,
          ...enhancedEventProperties,
        },
        createConsentedDestinations(),
      );
    };

    Sentry.addBreadcrumb({
      category: 'Purchase',
      level: 'info',
    });

    event();
  },
  setUserProperties: (userId, userProperties) => {
    const event = () => {
      if (!window.rudderanalytics) {
        return;
      }

      window.rudderanalytics.identify(
        userId,
        userProperties,
        createConsentedDestinations(),
      );
    };

    event();
  },
  identify: (userId) => {
    const event = () => {
      if (!window.rudderanalytics) {
        return;
      }

      window.rudderanalytics.identify(
        userId,
        {},
        createConsentedDestinations(),
      );
    };

    event();
  },
  unidentify: () => {
    const event = () => {
      if (!window.rudderanalytics) {
        return;
      }
      window.rudderanalytics.identify(
        undefined,
        {},
        createConsentedDestinations(),
      );
    };

    event();
  },
};

initAvo(
  {
    env:
      process.env['NEXT_PUBLIC_RUNTIME_ENV'] === RUNTIME_ENV.production
        ? AvoEnv.Prod
        : AvoEnv.Dev,
    webDebugger: false,
  },
  {},
  rudderstackSDKBridge,
);

export default Avo;
