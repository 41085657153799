import { forwardRef } from 'react';
import Box, { BoxProps } from '../Box/Box';

const Stack = forwardRef<
  HTMLDivElement,
  Omit<BoxProps, 'display' | 'flexDirection'>
>((props, forwardedRef) => (
  <Box display="flex" flexDirection="column" {...props} ref={forwardedRef} />
));

Stack.displayName = 'Stack';

export default Stack;
