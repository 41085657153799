'use client';

import { createContext, ReactNode, useContext } from 'react';
import { Partner, Language } from '@shared/types/enums';

export type Constants = {
  locale: Language;
  partner: Partner;
};

const context = createContext<Constants>({
  partner: Partner.Dohop,
  locale: Language.English,
});

export const ConstantProvider = ({
  children,
  locale,
  partner,
}: {
  children: ReactNode;
  locale?: string;
  partner: Partner;
}) => {
  return (
    <context.Provider value={{ partner, locale: locale as Language }}>
      {children}
    </context.Provider>
  );
};

export const useConstants = () => useContext(context);
