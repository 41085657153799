import { useEffect } from 'react';
import { Route } from 'next';
import { buttonClicked, linkClicked } from '@web/utils/analytics/Avo';
import usePartnerRouter from './usePartnerRouter';

const ALLOW_LIST_IDS = ['continue-button', 'cnx', 'tiered', 'confirmation'];

const findParentWithClickListener = (
  element: HTMLElement,
): HTMLElement | null => {
  if (!element.parentElement) {
    return null;
  }

  const { parentElement } = element;

  if (parentElement.onclick) {
    return parentElement;
  }

  return findParentWithClickListener(parentElement);
};

const triggerEvent = (element: HTMLElement | null, route: Route) => {
  if (!element) {
    return;
  }

  const id = element.getAttribute('data-cy');

  if (
    (element.tagName === 'BUTTON' || element.role === 'button') &&
    id &&
    element.onclick &&
    ALLOW_LIST_IDS.some((allowedId) => id.includes(allowedId))
  ) {
    buttonClicked({ id, path: route });
  } else if (element.tagName === 'A') {
    const url = element.getAttribute('href');
    linkClicked({ url, path: route });
  }
};

const useCreateGlobalAnalyticsClickHandlers = () => {
  const { route } = usePartnerRouter();

  useEffect(() => {
    const handleButtonClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (target.onclick) {
        triggerEvent(target, route);

        return;
      }

      const clickedEventElement = findParentWithClickListener(target);

      triggerEvent(clickedEventElement, route);
    };

    document.addEventListener('click', handleButtonClick, { capture: true });

    return () => {
      document.removeEventListener('click', handleButtonClick, {
        capture: true,
      });
    };
  }, [route]);
};

export default useCreateGlobalAnalyticsClickHandlers;
