/**
 * Wrapper component that only renders it's children when not
 * in a production environment
 */
const DevOnly = ({
  children,
  devKey,
}: {
  children?: React.ReactNode;
  devKey?: string;
}) => {
  // If there's a key provided, render children when the corresponding flag is true
  if (devKey && process.env[devKey]) {
    return children;
    // Default to rendering children when not in production
  } else if (process.env['NEXT_PUBLIC_RUNTIME_ENV'] !== 'production') {
    return children;
  }

  return null;
};

export default DevOnly;
