import Box from '@ui-v2/core/Box/Box';
import Icon, { IconProps } from '@ui-v2/core/Icon/Icon';
import Text, { TextProps } from '@ui-v2/core/Text/Text';
import Tooltip from '@ui-v2/core/Tooltip/Tooltip';
import { IconType } from '@ui-v2/types/typography';

type IconSize = 'small' | 'medium' | 'large';

type Props = {
  icon?: IconType;
  iconText?: string;
  id?: string;
  size?: IconSize;
  tooltip?: string;
};

const iconSizeValueMapper: Record<IconSize, IconProps['size']> = {
  small: 20,
  medium: 16, // It's smaller on medium by design
  large: 24,
};

const iconContainerSizeValueMapper: Record<IconSize, IconProps['size']> = {
  small: 24,
  medium: 32,
  large: 48,
};

const iconSizeTextVariantMapper: Record<IconSize, TextProps['variant']> = {
  small: 'heading-7',
  medium: 'heading-7',
  large: 'heading-5',
};

const PassengerServiceIconInner = ({
  icon,
  iconText,
  size = 'small',
}: Props) => (
  <Box
    alignItems="center"
    bg="surface.secondary"
    borderRadius="cardSecondary"
    display="flex"
    height={iconContainerSizeValueMapper[size]}
    justifyContent="center"
    widthCustom={iconContainerSizeValueMapper[size]}
  >
    {iconText && (
      <Text
        as="span"
        colour="text.subdued"
        variant={iconSizeTextVariantMapper[size]}
      >
        {iconText}
      </Text>
    )}
    {!iconText && icon && <Icon size={iconSizeValueMapper[size]} type={icon} />}
  </Box>
);

/**
 * A service icon with an optional tooltip and either an icon or a text (15kgs fx)
 */
const PassengerServiceIcon = ({
  id,
  size = 'small',
  tooltip,
  ...props
}: Props) =>
  tooltip && id ? (
    <Tooltip id={id} text={tooltip}>
      <PassengerServiceIconInner {...props} size={size} />
    </Tooltip>
  ) : (
    <PassengerServiceIconInner {...props} size={size} />
  );

export default PassengerServiceIcon;
