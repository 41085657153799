import { ThemeInterface } from '@ui-v2/theme/theme';
import { ThemeResponsiveProp } from '@ui-v2/types/props';

export type GetFieldType<Obj, Path> =
  Path extends `${infer Left}.${infer Right}`
    ? Left extends keyof Obj
      ?
          | GetFieldType<Exclude<Obj[Left], undefined>, Right>
          | Extract<Obj[Left], undefined>
      : undefined
    : Path extends keyof Obj
      ? Obj[Path]
      : undefined;

export const getValue = <
  TData,
  TPath extends string,
  TDefault = GetFieldType<TData, TPath>,
>(
  path: TPath,
  data: TData,
  defaultValue?: TDefault,
): GetFieldType<TData, TPath> | TDefault => {
  const value = path
    .split(/[.[\]]/)
    .filter(Boolean)
    .reduce<GetFieldType<TData, TPath>>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value, key) => (value as any)?.[key],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data as any,
    );

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return value !== undefined ? value : (defaultValue as TDefault);
};

export const retrieveThemeValues = (
  record:
    | Partial<ThemeInterface>
    | Partial<ThemeInterface['borders']>
    | Partial<ThemeInterface['colours']>
    | Partial<ThemeInterface['shadows']>
    | Partial<ThemeInterface['shape']>
    | Partial<ThemeInterface['sizes']>
    | Partial<ThemeInterface['spacings']>
    | Partial<ThemeInterface['typography']>
    | Partial<ThemeInterface['zIndices']>,
  value: ThemeResponsiveProp<string> | undefined,
) => {
  if (Array.isArray(value)) {
    return value.map((x) => getValue(x, record));
  }

  if (value) {
    return getValue(value, record);
  }

  return null;
};
