export type QueryString = {
  [x: string]: string | string[] | undefined | null | number | boolean;
};

export const toQueryParams = (query: QueryString): string => {
  const params = Object.keys(query).reduce<string[]>((result, key) => {
    const value = query[key];

    if (Array.isArray(value)) {
      return [
        ...result,
        ...value.map((val) => `${key}=${encodeURIComponent(val)}`),
      ];
    }

    if (value) {
      return [...result, `${key}=${encodeURIComponent(value)}`];
    }

    return result;
  }, []);

  return `${params.length > 0 ? `?${params.join('&')}` : ''}`;
};
