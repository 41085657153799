import { errorLogger } from '@utils/helperUtils';
import DefaultErrorView from './DefaultErrorView';
import ErrorBoundary from './ErrorBoundary';

const DefaultErrorBoundary = ({
  children,
  showErrorMessage,
  text,
}: {
  children: React.ReactNode;
  showErrorMessage?: boolean;
  text: string;
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <DefaultErrorView
          error={error}
          showErrorMessage={showErrorMessage}
          text={text}
        />
      )}
      errorLogger={errorLogger}
    >
      {children}
    </ErrorBoundary>
  );
};

export default DefaultErrorBoundary;
