import { ReactNode } from 'react';
import { I18nConfig } from 'next-translate';
import I18nProvider from 'next-translate/I18nProvider';
import { useConstants } from '@context/ConstantContext';
import { ApplicationStringNamespace } from '@shared/types/enums';
import { i18nConfig } from '@web/utils/i18n';
import useApplicationStrings from './hooks/useApplicationStrings';

const ApplicationStringProvider = ({ children }: { children: ReactNode }) => {
  const { locale, partner } = useConstants();

  const { i18nDict } = useApplicationStrings([
    ApplicationStringNamespace.Shared,
    partner,
  ]);

  return (
    <I18nProvider
      config={i18nConfig as I18nConfig}
      lang={locale}
      namespaces={{ common: i18nDict }}
    >
      {children}
    </I18nProvider>
  );
};

export default ApplicationStringProvider;
