import { Dayjs } from 'dayjs';
import { getUTCDaysBetween } from './dateUtils';

export const getNumberOfStops = (itinerary: {
  homebound: { legs: unknown[] }[];
  outbound: { legs: unknown[] }[];
}) => {
  const { homebound, outbound } = itinerary;

  const outboudLegs = outbound.flatMap((route) => route.legs);
  const homeboundLegs = homebound.flatMap((route) => route.legs);
  const outboundStops = outboudLegs.length > 0 ? outboudLegs.length - 1 : 0;
  const homeboundStops =
    homeboundLegs.length > 0 ? homeboundLegs.length - 1 : 0;

  return outboundStops + homeboundStops;
};

interface PartialLeg {
  arrival: Dayjs;
  departure: Dayjs;
  duration: number;
}

interface PartialRoute {
  arrival: Dayjs;
  departure: Dayjs;
  duration: number;
  legs: PartialLeg[];
}

export const getTotalDuration = (routes: PartialRoute[]): number => {
  return routes
    .flatMap((route) => route.legs)
    .reduce((acc, leg, idx, legs) => {
      const legDuration = leg.duration || 0;
      const stopDuration =
        legs[idx + 1]?.departure.diff(leg.arrival, 'seconds') || 0;

      return acc + legDuration + stopDuration;
    }, 0);
};

export const boundHasOvernightStay = (routes: PartialRoute[]): boolean => {
  const allLegs = routes.flatMap((route) => route.legs);

  for (let i = 1; i < allLegs.length; i++) {
    const previousLeg = allLegs[i - 1];
    const currentLeg = allLegs[i];

    if (
      previousLeg &&
      currentLeg &&
      getUTCDaysBetween(previousLeg.arrival, currentLeg.departure) > 0
    ) {
      return true;
    }
  }

  return false;
};

// Returns true if offer contains an overnight stopover
export const isOvernightStay = (bounds: PartialRoute[][]): boolean =>
  bounds.some((bound) => bound.length > 1 && boundHasOvernightStay(bound));

// Returns true if offer contains an overnight (red eye) flight
export const isOvernightFlight = (routes: PartialRoute[]): boolean => {
  const allLegs = routes.flatMap((route) => route.legs);

  return allLegs.some((leg) => {
    return !leg.departure.isSame(leg.arrival, 'date');
  });
};
