import useCmsTranslation from '@hooks/useCmsTranslation';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import Icon from '@ui-v2/core/Icon/Icon';
import Modal from '@ui-v2/core/Modal/Modal';
import Text from '@ui-v2/core/Text/Text';
import useGetOffer from '@web/context/hooks/useGetOffer';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';
import { useStep } from '@web/context/StepContext';
import { createSearchUrlFromBookingDeeplink } from '@web/utils/booking/bookingUtils';
import { getIatasFromSummary } from '@web/utils/booking/summaryUtils';
import useVendorPassengerRules from '../Booking/hooks/useVendorPassengerRules';

const SessionTimeoutModal = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useCmsTranslation();
  const { query } = usePartnerRouter();
  const { steps } = useStep();
  const { offer } = useGetOffer();
  const iatas = getIatasFromSummary(offer?.summary);
  const { vendorPassengerRules } = useVendorPassengerRules(iatas);

  const searchUrl = createSearchUrlFromBookingDeeplink(
    query,
    vendorPassengerRules,
    steps,
  );

  return (
    <Modal
      footer={
        <Box display="flex" justifyContent="flex-end">
          {/* Use a server routing link here to ensure a full refresh of the page */}
          <a href={searchUrl ?? '/'}>
            <Button isLoading={!searchUrl} size="small">
              {t('refresh_itinerary', 'Refresh itinerary')}
            </Button>
          </a>
        </Box>
      }
      header={
        <Box alignItems="center" display="flex" gap={4}>
          <Icon colour="icons.on.warning" size={20} type="warningIcon" />
          <Text as="h3" variant="heading-5">
            {t('session_expired', 'Session has expired')}
          </Text>
        </Box>
      }
      id="session-timeout-modal"
      isOpen={isOpen}
      maxWidth={632}
      showCloseButton={false}
    >
      <Box maxWidth={['full', '8/12']}>
        {t(
          'session_expired_description',
          'Your session has expired. Please refresh to see the most up-to-date prices and availability.',
        )}
      </Box>
    </Modal>
  );
};

export default SessionTimeoutModal;
