import { css } from '@emotion/react';

const jazeeraFonts = css`
  @font-face {
    font-display: fallback;
    font-family: '29LT Bukra';
    src: url('/fonts/Jazeera/29LT_Bukra_Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: ltbukra-semibold;
    src: url('/fonts/Jazeera/29LT_Bukra_Semi_Bold.woff2');
  }
`;

export default jazeeraFonts;
