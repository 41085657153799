import { css } from '@emotion/react';

const aviancaFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Red Hat Display';
    src: url('/fonts/Avianca/RedHatDisplay-VariableFont.woff2');
  }
`;

export default aviancaFonts;
