import { CSSProperties, ReactNode, useEffect } from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as Dialog from '@radix-ui/react-dialog';
import { ThemePaddingProps } from '@ui-v2/types/props';
import { useBaseAnalytics } from '@ui-v2/utils/contexts/BaseAnalyticsContext';
import { mqMax, mqMin } from '@ui-v2/utils/styleUtils';
import Box from '../Box/Box';
import Button from '../Button/Button';
import Text from '../Text/Text';

export type ModalProps = {
  autoFocus?: boolean;
  children?: React.ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  id: string;
  isOpen: boolean;
  maxWidth?: CSSProperties['maxWidth'];
  mobileSize?: 'full-screen' | 'drawer';
  onOpenChange?: (isOpen: boolean) => void;
  showCloseButton?: boolean;
  subTitle?: string;
  title?: string;
  zIndex?: {
    desktop?: number;
    mobile?: number;
  };
} & ThemePaddingProps;

const MODAL_DISPLAY_BREAKPOINT = 768;
const DEFAULT_MAX_WIDTH = 944;

const overlayShow = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const contentHide = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
`;

const contentShowMobile = keyframes`
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
`;

const StyledOverlay = styled(Dialog.Overlay)(
  ({ theme }) => css`
    position: fixed;
    z-index: ${theme.zIndices.modal - 1};
    animation: ${overlayShow} 400ms cubic-bezier(0.16, 1, 0.3, 1);
    background: ${theme.colours.background.backdrop};
    inset: 0;
  `,
);

const StyledTitle = styled(Dialog.Title)(
  () => css`
    position: sticky;
    z-index: 2;
    top: 0;
  `,
);

const StyledContent = styled(Dialog.Content, {
  shouldForwardProp: (prop) =>
    prop !== 'mobileSize' && prop !== 'zIndex' && prop !== 'maxWidth',
})<{
  maxWidth?: ModalProps['maxWidth'];
  mobileSize: ModalProps['mobileSize'];
}>(({ maxWidth, mobileSize, theme }) => [
  css`
    position: fixed;
    z-index: ${theme.zIndices.modal};
    bottom: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 'auto';
    max-height: 100vh;
    max-height: 100dvh;
    flex-direction: column;
    border-radius: ${theme.shape.modal}px;
    animation: ${contentShowMobile} 300ms ease;
    background-color: ${theme.colours.surface.main};
    box-shadow: ${theme.shadows.medium};
    overflow-y: auto;
    transform: translateY(0);
    ${mqMin[MODAL_DISPLAY_BREAKPOINT]} {
      top: 50%;
      bottom: initial;
      left: 50%;
      width: 90vw;
      max-width: ${maxWidth ?? `${DEFAULT_MAX_WIDTH}px`};
      height: auto;
      max-height: 85vh;
      transform: translate(-50%, -50%);

      &[data-state='closed'] {
        animation: ${contentHide} 200ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
      }

      &[data-state='open'] {
        animation: ${contentShow} 200ms cubic-bezier(0.16, 1, 0.3, 1);
      }
    }
  `,
  mobileSize === 'full-screen' &&
    css`
      ${mqMax[MODAL_DISPLAY_BREAKPOINT]} {
        height: 100vh;
        animation: none;
      }
    `,
]);

const Modal = ({
  autoFocus = true,
  children,
  footer,
  header,
  id,
  isOpen,
  maxWidth,
  mobileSize = 'drawer',
  onOpenChange,
  showCloseButton = true,
  subTitle,
  title,
  zIndex,
  ...props
}: ModalProps) => {
  const { sendModalShownEvent } = useBaseAnalytics();

  useEffect(() => {
    if (isOpen) {
      sendModalShownEvent({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const shouldShowTitleBox = title || showCloseButton || header;

  return (
    <Dialog.Root onOpenChange={onOpenChange} open={isOpen}>
      <Dialog.Portal>
        <StyledOverlay />
        <StyledContent
          maxWidth={typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth}
          mobileSize={mobileSize}
          onOpenAutoFocus={(e) => {
            if (!autoFocus) {
              e.preventDefault();
            }
          }}
        >
          {shouldShowTitleBox && (
            <StyledTitle asChild>
              <Box
                alignItems="center"
                bg="surface.main"
                borderBottom="subdued"
                display="flex"
                justifyContent="space-between"
                p={16}
              >
                {(title || subTitle) && (
                  <Box>
                    {title && (
                      <Text as="h2" variant="heading-4">
                        {title}
                      </Text>
                    )}
                    {subTitle && (
                      <Text as="h2" colour="text.subdued" variant="heading-7">
                        {subTitle}
                      </Text>
                    )}
                  </Box>
                )}
                {header && header}
                {showCloseButton && (
                  <Dialog.Close asChild>
                    <Button
                      aria-label="Close"
                      icon="closeIcon"
                      size="iconRegular"
                      variant="tertiary"
                    />
                  </Dialog.Close>
                )}
              </Box>
            </StyledTitle>
          )}
          <Box flexGrow={1} p={16} {...props}>
            {children}
          </Box>
          {footer && (
            <Box
              bg="surface.main"
              borderTop="subdued"
              bottom={0}
              data-cy="sticky-footer"
              p={16}
              position="sticky"
              width="100%"
              zIndex="2"
            >
              {footer}
            </Box>
          )}
        </StyledContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
