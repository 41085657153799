import { ThemeV4Fragment } from '@codegen/cmsUtils';
import { ThemeBoxShadow } from '@ui-v2/types/borders';

export const buildShadows = (theme: ThemeV4Fragment): ThemeBoxShadow => ({
  ...theme.boxShadow,
  none: 'none',
  threeTieredConnections,
});

const threeTieredConnections = {
  card: '0px 30px 8px 0px rgba(0, 0, 0, 0.00), 0px 19px 8px 0px rgba(0, 0, 0, 0.01), 0px 11px 7px 0px rgba(0, 0, 0, 0.05), 0px 5px 5px 0px rgba(0, 0, 0, 0.09), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
};
