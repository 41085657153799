import {
  Dimensions,
  Passenger,
  ServiceClass,
  Summary,
} from '@codegen/offerAPI';
import useCmsTranslation from '@hooks/useCmsTranslation';
import Box from '@ui-v2/core/Box/Box';
import GridColumn from '@ui-v2/core/Grid/GridColumn';
import GridRow from '@ui-v2/core/Grid/GridRow';
import Stack from '@ui-v2/core/Stack/Stack';
import Text from '@ui-v2/core/Text/Text';
import { IconType } from '@ui-v2/types/typography';
import { useServiceConfig } from '@web/context/hooks/useServiceConfig/useServiceConfig';
import useVendors from '@web/context/hooks/useVendors';
import PassengerServiceIcon from '@web/core/Icons/PassengerServiceIcon';
import { getBagsOfType } from '@web/utils/booking/summaryUtils';
import { PassengerSummaryItem } from '../Shared/PassengerServiceSections';
import { getLegIdsFromSummary } from '../utils/utils';

type Props = {
  hideHeaderSection?: boolean;
  passenger: Passenger;
  summary: Summary;
};

const LuggageItem = ({
  dimensions,
  icon,
  iconText,
  title,
}: {
  dimensions: Dimensions | null;
  icon?: IconType;
  iconText?: string | null;
  title: string;
}) => {
  return (
    <Box alignItems="center" display="flex" gap={8}>
      <PassengerServiceIcon
        icon={icon}
        iconText={iconText ?? undefined}
        size="medium"
      />
      <Box>
        <Text as="div" variant="body-2">
          {title}
        </Text>
        {dimensions?.width && dimensions.height && dimensions.length && (
          <Text as="div" colour="text.subdued" variant="body-3">
            {dimensions.width} x {dimensions.height} x {dimensions.length} cm
          </Text>
        )}
      </Box>
    </Box>
  );
};

const PassengerLuggageSummary = ({
  hideHeaderSection = false,
  passenger,
  summary,
}: Props) => {
  const { t } = useCmsTranslation();
  const legs = summary.leg_summaries.flatMap((leg) => leg.legs);
  const { getServiceTitle } = useServiceConfig();

  const iatas = legs.map((leg) => leg.marketing_carrier.code);
  const { getSingleVendor } = useVendors({
    iatas,
  });

  const totalLegsIds = getLegIdsFromSummary(summary);

  const isAllSameVendor = iatas.every((x) => x === iatas[0]);

  return (
    <Box>
      {!hideHeaderSection && (
        <Box alignItems="center" display="flex" gap={8} mb={12}>
          <PassengerServiceIcon icon="luggageIcon" size="medium" />
          <Text as="h5" variant="heading-5">
            {t('baggage', 'Luggage')}
          </Text>
        </Box>
      )}

      <GridRow mb={[12]} overflowX="visible" spacing="small">
        {summary.leg_summaries.flatMap((legSummary) =>
          legSummary.legs.map((leg) => {
            const vendor = getSingleVendor(leg.marketing_carrier.code);

            const includedServices = legSummary.included_services;
            const additionalServices = legSummary.additional_services.filter(
              (x) => x.passenger_id === passenger.passenger_id,
            );

            const personalLuggageItems = getBagsOfType({
              includedServices,
              additionalServices,
              serviceClass: ServiceClass.luggage_personal,
            });

            const cabinBags = getBagsOfType({
              includedServices,
              additionalServices,
              serviceClass: ServiceClass.luggage_cabin,
            });

            const checkedBags = getBagsOfType({
              includedServices,
              additionalServices,
              serviceClass: ServiceClass.luggage_checked,
            });

            return (
              <GridColumn
                key={leg.leg_id}
                spacing="small"
                width={['full', '6/12', '3/12']}
              >
                <PassengerSummaryItem
                  itemNumber={totalLegsIds.indexOf(leg.leg_id) + 1}
                  leg={leg}
                  numberOfLegs={legs.length}
                  // Don't show vendor logos if it's all the same vendor (easy-easy fx)
                  vendorLogo={isAllSameVendor ? undefined : vendor?.logo}
                >
                  <Stack gap={8}>
                    {personalLuggageItems.map((personalLuggage, index) => (
                      <LuggageItem
                        dimensions={personalLuggage.dimensions ?? null}
                        icon="backpackIcon"
                        key={`${personalLuggage.service_class}-${index.toString()}`}
                        title={getServiceTitle(personalLuggage)}
                      />
                    ))}
                    {cabinBags.map((cabinBag, index) => (
                      <LuggageItem
                        dimensions={cabinBag.dimensions ?? null}
                        icon="cabinBagIcon"
                        key={`${cabinBag.service_class}-${index.toString()}`}
                        title={getServiceTitle(cabinBag)}
                      />
                    ))}

                    {checkedBags.map((checkedBag, index) => (
                      <LuggageItem
                        dimensions={checkedBag.dimensions ?? null}
                        icon="checkedBagIcon"
                        key={`${checkedBag.service_class}-${index.toString()}`}
                        title={getServiceTitle(checkedBag)}
                      />
                    ))}
                  </Stack>
                </PassengerSummaryItem>
              </GridColumn>
            );
          }),
        )}
      </GridRow>
    </Box>
  );
};

export default PassengerLuggageSummary;
