import { QueryClient } from '@tanstack/react-query';
import {
  useDohopConnectQuery,
  useGetHeaderConfigQuery,
  useGetPartnerQuery,
} from '@codegen/cmsUtils';
import {
  useCountriesQuery,
  useCurrenciesQuery,
  ApplicationStringsV2QueryVariables,
  useApplicationStringsV2Query,
} from '@codegen/gatewayUtils';
import {
  ApplicationStringNamespace,
  Language,
  Partner,
} from '@shared/types/enums';
import { parseApplicationStrings } from './applicationStringUtils';

// Checks if a query is cached on the client, this can be used to avoid refetching queries during client side transitions
const checkIfQueryIsCached = <Variables>(queryKey: (string | Variables)[]) => {
  if (typeof window !== 'undefined') {
    return window.__NEXT_DATA__.props.dehydratedState.queries.find(
      (query: { queryHash: string }) => {
        return query.queryHash === JSON.stringify(queryKey);
      },
    );
  }

  return false;
};

export const prefetchPartnerConfig = async ({
  partner,
  queryClient,
}: {
  partner: Partner;
  queryClient: QueryClient;
}) => {
  return await queryClient.fetchQuery(
    useGetPartnerQuery.getKey({ partner }),
    async () => await useGetPartnerQuery.fetcher({ partner })(),
    { retry: 2 },
  );
};

export const prefetchDohopConnect = async ({
  locale,
  queryClient,
}: {
  locale: Language;
  queryClient: QueryClient;
}) =>
  queryClient.prefetchQuery(
    useDohopConnectQuery.getKey({ locale }),
    async () => await useDohopConnectQuery.fetcher({ locale })(),
    { retry: 2 },
  );

export const prefetchUserSettingsConfig = async ({
  language,
  queryClient,
}: {
  language: Language;
  queryClient: QueryClient;
}) => {
  await queryClient.prefetchQuery(
    useCountriesQuery.getKey({ language }),
    async () => await useCountriesQuery.fetcher({ language })(),
    { retry: 2 },
  );

  await queryClient.prefetchQuery(
    useCurrenciesQuery.getKey({ language }),
    async () => await useCurrenciesQuery.fetcher({ language })(),
    { retry: 2 },
  );
};

export const getApplicationStrings = async ({
  locale,
  partner,
  queryClient,
}: {
  locale: Language;
  partner: Partner;
  queryClient: QueryClient;
}) => {
  const namespaces = [ApplicationStringNamespace.Shared, partner];
  const queryKey = useApplicationStringsV2Query.getKey({
    locale,
    namespaces,
  });

  if (checkIfQueryIsCached<ApplicationStringsV2QueryVariables>(queryKey)) {
    return;
  }

  const applicationStringsResult = await queryClient.fetchQuery(
    useApplicationStringsV2Query.getKey({ namespaces, locale }),
    async () =>
      await useApplicationStringsV2Query.fetcher({
        namespaces,
        locale,
      })(),
    { retry: 2 },
  );

  return parseApplicationStrings(applicationStringsResult);
};

export const prefetchHeaderConfig = async ({
  locale,
  partner,
  queryClient,
}: {
  locale: Language;
  partner: Partner;
  queryClient: QueryClient;
}) =>
  queryClient.prefetchQuery(
    useGetHeaderConfigQuery.getKey({ partner, locale }),
    async () => await useGetHeaderConfigQuery.fetcher({ partner, locale })(),
    { retry: 2 },
  );
