import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeMarginProps } from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { hexToRGBA, mqMax } from '@ui-v2/utils/styleUtils';
import { buildMargin } from '@ui-v2/utils/themePropBuilders';
import Box from '../../Box/Box';
import Icon, { IconProps } from '../../Icon/Icon';
import InputError from '../Input/InputError';
import InputLabel from '../Input/InputLabel';
import {
  InputIconLeft,
  InputState,
  TRANSITION_SPEED,
  textColourMapper,
} from '../Input/SharedInputComponents';
import { getInputIconLeft, getInputState } from '../Input/utils';

export type SelectOption = {
  label: string;
  value: string | number;
};

export type SelectProps = Omit<
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > &
    Pick<ThemeMarginProps, 'mt' | 'mb'> & {
      errorMessage?: string;
      iconLeft?: IconProps['type'];
      iconRight?: IconProps['type'];
      label?: string;
      name: string;
      placeholder?: string;
      // Show a different label when a value has been selected
      selectedLabel?: string;
      values?:
        | Array<SelectOption>
        | Array<{
            label: string;
            values: Array<SelectOption>;
          }>;
    },
  'ref'
>;

export const StyledWrapper = styled('div')<ThemeMarginProps>(
  ({ theme, ...props }) => [
    css`
      position: relative;
      width: 100%;
    `,
    css(
      buildResponsiveValues({
        ...buildMargin(props),
      }),
    ),
  ],
);

/**
 * Rule: margin-bottom: 2px is there to account for the 2px wide outline which
 * is not recognized within the box-sizing system
 */
export const StyledSelect = styled('select')<
  Omit<SelectProps, 'label' | 'arrowIcon'> & {
    hasIconLeft: boolean;
    state: InputState;
  }
>(({ hasIconLeft, state, theme }) => [
  css`
    display: block;
    width: 100%;
    padding: ${theme.spacings[12]}px ${theme.spacings[32]}px
      ${theme.spacings[12]}px ${theme.spacings[16]}px;
    border: none;
    border-radius: ${theme.shape.input}px;
    margin-right: ${theme.spacings[2]}px;
    margin-bottom: ${theme.spacings[2]}px;
    appearance: none;
    background: ${theme.colours.surface.default};
    color: ${theme.colours.text.default};
    font-family: ${theme.typography.body01.fontFamily};
    font-size: 16px;
    outline: 1px solid ${theme.colours.border.default};
    transition:
      color ${TRANSITION_SPEED},
      border-color ${TRANSITION_SPEED},
      padding-right ${TRANSITION_SPEED};

    &:hover {
      z-index: 2;
      outline: 1px solid ${theme.colours.border.interactive};
    }

    &:active,
    &:focus {
      z-index: 2;
      outline: 2px solid ${theme.colours.border.interactive};
    }

    &:required:invalid {
      color: ${theme.colours.text.disabled};
    }

    ${mqMax[768]} {
      padding: ${theme.spacings[12]}px ${theme.spacings[32]}px
        ${theme.spacings[12]}px ${theme.spacings[8]}px;
    }
  `,
  state === 'error' &&
    css`
      outline: 2px solid ${theme.colours.border.critical};
    `,
  state === 'disabled' &&
    css`
      color: ${theme.colours.text.disabled};
      outline: 2px solid ${hexToRGBA(theme.colours.border.default, 0.5)};

      &:hover {
        outline: 2px solid ${hexToRGBA(theme.colours.border.default, 0.8)};
      }
    `,
  hasIconLeft &&
    css`
      padding-left: 48px;
    `,
]);

// eslint-disable-next-line react/display-name
export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (props, forwardedRef) => {
    const {
      children,
      disabled,
      errorMessage,
      iconLeft,
      iconRight,
      id,
      label,
      mb,
      mt,
      placeholder,
      selectedLabel,
      values,
      ...rest
    } = props;

    const state = getInputState({
      disabled,
      errorMessage,
    });

    const renderedIconLeft = getInputIconLeft({
      iconLeft,
    });

    return (
      <StyledWrapper mb={mb} mt={mt}>
        {label && (
          <InputLabel
            colour={textColourMapper[state]}
            htmlFor={id}
            label={label}
          />
        )}
        <Box display="flex" position="relative">
          {renderedIconLeft && (
            <InputIconLeft state={state} type={renderedIconLeft} />
          )}
          {/* Show a custom "selected" value in a little hacky way  */}
          {selectedLabel && (
            <Box
              alignItems="center"
              bg="surface.default"
              borderRadius="input"
              bottom={2}
              display="flex"
              left={0}
              pointerEvents="none"
              position="absolute"
              px={16}
              py={12}
              right={2}
              top={0}
              zIndex="3"
            >
              {selectedLabel}
            </Box>
          )}
          <StyledSelect
            disabled={disabled}
            hasIconLeft={Boolean(renderedIconLeft)}
            id={id}
            ref={forwardedRef}
            state={state}
            {...rest}
          >
            {placeholder && (
              <option disabled value="">
                {placeholder}
              </option>
            )}
            {children}
            {/* Optional prop of providing the values as a value/label array instead of creating options by hand */}
            {values?.map((item) => {
              /**
               * See above type, values can be split up into an array of items or option groups with values
               */
              if ('values' in item) {
                return (
                  <optgroup key={item.label} label={item.label}>
                    {item.values.map((value) => (
                      <option key={value.label} value={value.value}>
                        {value.label}
                      </option>
                    ))}
                  </optgroup>
                );
              }

              return (
                <option key={item.label} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </StyledSelect>

          <Box
            alignItems="center"
            display="flex"
            height="100%"
            pointerEvents="none"
            position="absolute"
            right={[8, 8, 12]}
            top={0}
            zIndex="4"
          >
            <Icon size={24} type="chevronDownIcon" />
          </Box>
        </Box>
        {state === 'error' && <InputError id={id}>{errorMessage}</InputError>}
      </StyledWrapper>
    );
  },
);

export default Select;
