import { useState, useEffect } from 'react';

const useIsOnClient = (): boolean => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // This effect will only run on the client, never on the server.
    setIsClient(typeof window !== 'undefined');
  }, []);

  return isClient;
};

export default useIsOnClient;
