import { css } from '@emotion/react';
import styled from '@emotion/styled';

const ListItem = styled.li(
  ({ theme: { colours } }) => css`
    color: ${colours.text.default};
  `,
);

export default ListItem;
