import type { CSSProperties } from 'react';
// eslint-disable-next-line no-restricted-imports
import NextImage, { ImageLoaderProps, ImageProps } from 'next/image';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconColourProp } from '@ui-v2/types/props';
import { retrieveThemeValues } from '@ui-v2/utils/retrieveThemeValues';
import { createCSSFilterFromHex } from '@ui-v2/utils/styleUtils';

type BaseProps = Omit<ImageProps, 'src' | 'alt'> & {
  alt?: string | null;
  colour?: IconColourProp;
  focalPoint?: { x: number; y: number } | null;
  objectFit?: CSSProperties['objectFit'];
  objectPosition?: CSSProperties['objectPosition'];
  src: string;
};

type WithWidthAndHeight = Omit<BaseProps, 'width' | 'height' | 'fill'> & {
  fill?: false;
  height?: ImageProps['height'] | undefined | null;
  width?: ImageProps['width'] | undefined | null;
};

type WithoutWidthAndHeight = Omit<BaseProps, 'width' | 'height' | 'fill'> & {
  fill: true;
  height?: never;
  width?: never;
};

type Props = WithWidthAndHeight | WithoutWidthAndHeight;

const StyledNextImage = styled(NextImage, {
  shouldForwardProp: (prop) =>
    prop !== 'fit' && prop !== 'colour' && prop !== 'position',
})<{
  colour: Props['colour'];
  fit: Props['objectFit'];
  position: Props['objectPosition'];
}>(({ colour, fit, position, theme }) => [
  colour &&
    createCSSFilterFromHex(
      retrieveThemeValues(theme.colours, colour) as unknown as string,
    ),
  css`
    object-fit: ${fit};
    object-position: ${position};
  `,
]);

const customLoader = ({ quality, src, width }: ImageLoaderProps) =>
  src.includes('svg')
    ? src
    : `${src}?w=${width}&q=${quality || 100}&auto=compress,enhance,format`;

const Image = ({
  alt,
  colour,
  fill,
  focalPoint,
  height,
  objectFit,
  priority,
  quality = 70,
  src,
  width,
  ...imageProps
}: Props) => {
  const isSvg = src.includes('.svg');

  const position = focalPoint
    ? `${focalPoint.x * 100}% ${focalPoint.y * 100}%`
    : '50% 50%';

  return (
    <StyledNextImage
      {...imageProps}
      alt={alt || ''}
      colour={colour}
      fill={fill}
      fit={isSvg ? 'contain' : objectFit}
      height={fill ? undefined : height ?? 0}
      loader={customLoader}
      position={position}
      priority={priority}
      quality={quality}
      src={src}
      unoptimized={isSvg}
      width={fill ? undefined : width ?? 0}
    />
  );
};

export default Image;
