import { useGetIconConfig } from '@context/IconConfigContext';
import { Partner } from '@shared/types/enums';

export const mapHostNameToPartner = (hostName?: string | null) => {
  const possiblePartners = Object.values(Partner);
  const partner = possiblePartners.find((partner) =>
    hostName?.includes(partner),
  );

  return partner || Partner.Dohop;
};

// We try to get the icon through the partners iconConfig, if the partner doesn't have one we use the dohop one
const useGetIcon = () => {
  const { getIcon } = useGetIconConfig();

  return getIcon;
};

export default useGetIcon;
