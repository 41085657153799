import { CurrencyFragment } from '@codegen/gatewayUtils';

export const getMainCurrencies = (
  currencies: CurrencyFragment[],
  mainCurrencies?: string[],
) => {
  return (
    mainCurrencies?.reduce<CurrencyFragment[]>((acc, currCode) => {
      const curr = currencies.find((curr) => curr.code === currCode);

      if (!curr) {
        return acc;
      }

      return [...acc, curr];
    }, []) ?? []
  );
};
