import { css } from '@emotion/react';

const hawaiianairlinesFonts = css`
  @font-face {
    font-display: fallback;
    font-family: SlatePro;
    src: url('/fonts/hawaiianairlines/slateProRegular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: SlatePro-Bold;
    src: url('/fonts/hawaiianairlines/slateProBold.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Chronicle Display';
    src: url('/fonts/hawaiianairlines/chronicleDisplaySemiBold.woff2');
  }
`;

export default hawaiianairlinesFonts;
