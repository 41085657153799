import { css } from '@emotion/react';

const vuelingFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/vueling/NunitoSans-Light.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/vueling/NunitoSans-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/vueling/NunitoSans-SemiBold.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/vueling/NunitoSans-Bold.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'NunitoSans-H2';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/vueling/NunitoSans-Bold.woff2');
  }
`;

export default vuelingFonts;
