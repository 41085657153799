import { IconProps } from '../../Icon/Icon';
import { InputState } from './SharedInputComponents';

export const getInputState = ({
  disabled,
  errorMessage,
}: {
  disabled: boolean | undefined;
  errorMessage: string | undefined;
}): InputState => {
  if (errorMessage) {
    return 'error';
  }

  if (disabled) {
    return 'disabled';
  }

  return 'default';
};

export const getInputIconRight = ({
  iconRight,
  state,
}: {
  iconRight?: IconProps['type'];
  state: InputState;
}): IconProps['type'] | null => {
  if (iconRight) {
    return iconRight;
  }

  if (state === 'error') {
    return 'warningIcon';
  }

  return null;
};

export const getInputIconLeft = ({
  iconLeft,
}: {
  iconLeft?: IconProps['type'];
}): IconProps['type'] | null => {
  if (iconLeft) {
    return iconLeft;
  }

  return null;
};
