import dayjs, { Dayjs } from 'dayjs';
import {
  OfferFragment,
  ItineraryFragment,
  OfferFilters,
  RouteFragment,
  AvailabilityNode,
} from '@codegen/gatewayUtils';
import {
  OfferResponse,
  PaxType,
  LegGroupSummary,
  Leg,
  Passenger,
  Summary,
  OrderSummaryResponseBookingsItem,
  OfferBundle,
  SegmentedItineraryWithCoveredConnections,
  DohopServiceBase,
  IncludedLuggage,
  AdditionalLuggage,
} from '@codegen/offerAPI';
import {
  CMSPassengerType,
  CurrencyCode,
  Language,
  Partner,
  Sort,
} from '@shared/types/enums';
import { toUTCLocaleString } from '@utils/dateUtils';
import { isDeepEqual, partition } from '@utils/helperUtils';
import { Experiment } from '@web/types/experimentTypes';
import {
  productListViewed,
  emptySearchResults,
  productClicked,
  productViewed,
  priceMismatch,
  addService,
  serviceMismatch,
  updatePassengers,
  UpdatePassengersProperties,
  orderCompleted,
  Products,
  paymentInfoEntered,
  Variant,
  bundleSelected,
  productListFiltered,
  additionalServicePurchase,
  createOrderError,
  SearchTypeValueType,
  BookingStatuses,
} from '@web/utils/analytics/Avo';
import { createConsentedDestinations } from '@web/utils/analytics/rudderstackUtils';
import { getServiceFromServiceId } from '@web/utils/booking/bookingUtils';
import { getIatasFromSummary } from '@web/utils/booking/summaryUtils';
import { getIatasFromOffers } from '@web/utils/offerUtils';

export const normalizeCurrency = ({
  currencyConversionRate,
  price,
}: {
  currencyConversionRate: number;
  price: number;
}) => Number((price / currencyConversionRate).toFixed(2));

export const pushToDataLayer = <T>(obj: T) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

export const constructProductName = (itinerary: ItineraryFragment) =>
  `${itinerary.outbound[0]?.origin.code}>${
    itinerary.outbound[itinerary.outbound.length - 1]?.destination.code
  }${
    itinerary.homebound.length > 0
      ? `|${itinerary.homebound[0]?.origin.code}>${
          itinerary.homebound[itinerary.outbound.length - 1]?.destination.code
        }`
      : ''
  }`;

export const constructMbsProductName = (leg_summaries: LegGroupSummary[]) => {
  const legs = leg_summaries.flatMap((leg_summary) => leg_summary.legs);

  const [outbound, homebound] = partition((leg) => leg.is_outbound, legs);

  return `${outbound[0]?.origin.airport_iata}>${
    outbound[outbound.length - 1]?.destination.airport_iata
  }${
    homebound.length > 0
      ? `|${homebound[0]?.origin.airport_iata}>${
          homebound[homebound.length - 1]?.destination.airport_iata
        }`
      : ''
  }`;
};

const getDohopServiceFromSummary = (summary: Summary) => {
  const dohopService = summary.other_services.find(
    (service): service is DohopServiceBase =>
      service.service_class === 'dohop_commission_fee' ||
      service.service_class === 'dohop_service_fee',
  );

  return dohopService;
};

export const getLuggageSummaryFromSummary = (
  summary: Summary,
  nonInfantPassengers: number,
  numberOfLegs: number,
  currencyConversionRate: number,
) => {
  const includedCabinBags: IncludedLuggage[] = summary.leg_summaries.flatMap(
    (leg_summary) =>
      leg_summary.included_services
        .filter(
          (includedService) =>
            includedService.service_class === 'luggage_cabin',
        )
        .map((includedService) => includedService as IncludedLuggage),
  );

  const includedCheckedBags: IncludedLuggage[] = summary.leg_summaries.flatMap(
    (leg_summary) =>
      leg_summary.included_services
        .filter(
          (includedService) =>
            includedService.service_class === 'luggage_checked',
        )
        .map((includedService) => includedService as IncludedLuggage),
  );

  const additionalCabinBags: AdditionalLuggage[] =
    summary.leg_summaries.flatMap((leg_summary) =>
      leg_summary.additional_services
        .filter(
          (additionalService) =>
            additionalService.service_class === 'luggage_cabin',
        )
        .map((includedService) => includedService as AdditionalLuggage),
    );

  const additionalCheckedBags: AdditionalLuggage[] =
    summary.leg_summaries.flatMap((leg_summary) =>
      leg_summary.additional_services
        .filter(
          (additionalService) =>
            additionalService.service_class === 'luggage_checked',
        )
        .map((includedService) => includedService as AdditionalLuggage),
    );

  const paxTimesLegs = nonInfantPassengers * numberOfLegs;

  const cabinBagsTotalQuantity =
    includedCabinBags.reduce(
      (total, bag) => total + bag.quantity * nonInfantPassengers,
      0,
    ) + additionalCabinBags.reduce((total, bag) => total + bag.quantity, 0);

  const checkedBagsTotalQuantity =
    includedCheckedBags.reduce(
      (total, bag) => total + bag.quantity * nonInfantPassengers,
      0,
    ) + additionalCheckedBags.reduce((total, bag) => total + bag.quantity, 0);

  const cabinBagsTotalPrice = additionalCabinBags.reduce(
    (total, bag) =>
      total +
      normalizeCurrency({
        currencyConversionRate,
        price: bag.price.amount * bag.quantity,
      }),
    0,
  );

  const checkedBagsTotalPrice = additionalCheckedBags.reduce(
    (total, bag) =>
      total +
      normalizeCurrency({
        currencyConversionRate,
        price: bag.price.amount * bag.quantity,
      }),
    0,
  );

  const cabinBagsSummary = {
    quantityTotal: cabinBagsTotalQuantity,
    totalAmountInEur: cabinBagsTotalPrice,
    quantityPerPaxPerLeg: cabinBagsTotalQuantity / paxTimesLegs,
  };

  const checkedBagsSummary = {
    quantityTotal: checkedBagsTotalQuantity,
    totalAmountInEur: checkedBagsTotalPrice,
    quantityPerPaxPerLeg: checkedBagsTotalQuantity / paxTimesLegs,
  };

  return { cabinBagsSummary, checkedBagsSummary };
};

export const getOverallOrderStatus = (
  bookingStatuses: BookingStatuses[],
): string => {
  if (
    bookingStatuses.every((bookingStatus) => bookingStatus.status === 'success')
  ) {
    return 'success';
  } else if (
    bookingStatuses.some((bookingStatus) => bookingStatus.status === 'success')
  ) {
    return 'partial success';
  } else if (
    bookingStatuses.every(
      (bookingStatus) =>
        bookingStatus.status === 'cancelled' ||
        bookingStatus.status === 'failed',
    )
  ) {
    return 'failed';
  }

  return 'mixed';
};

export const pushCheapestOfferToDatalayer = ({
  cheapestOffer,
  currency,
  residency,
}: {
  cheapestOffer?: OfferFragment | null;
  currency: CurrencyCode;
  residency: string;
}) => {
  if (!cheapestOffer) {
    return;
  }

  pushToDataLayer({
    price: cheapestOffer.price,
    currency,
    outboundOriginAirportCode: cheapestOffer.itinerary.outbound[0]?.origin,
    outboundDestinationAirportCode:
      cheapestOffer.itinerary.outbound[
        cheapestOffer.itinerary.outbound.length - 1
      ]?.destination,
    fareClass: 'ECONOMY',
    searchDate: Date.now(),
    departureDate: toUTCLocaleString({
      date: dayjs(cheapestOffer.itinerary.outbound[0]?.departure).toDate(),
      locale: Language.English,
      residency,
      options: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        hourCycle: 'h23',
      },
    }),

    returnDate:
      cheapestOffer.itinerary.homebound.length > 0
        ? toUTCLocaleString({
            date: dayjs(
              cheapestOffer.itinerary.homebound[0]?.departure,
            ).toDate(),
            locale: Language.English,
            residency,
            options: {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              weekday: 'long',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
              hourCycle: 'h23',
            },
          })
        : null,
    numberOfOutboundConnections: cheapestOffer.itinerary.outbound.length,
    numberOfHomeboundConnections: cheapestOffer.itinerary.homebound.length,
    outboundFlightDurationInSeconds:
      cheapestOffer.itinerary.outbound.reduce<number>(
        (acc, leg) => acc + leg.duration,
        0,
      ),
    homeboundFlightDurationInSeconds:
      cheapestOffer.itinerary.homebound.reduce<number>(
        (acc, leg) => acc + leg.duration,
        0,
      ),
    carriers: [
      ...cheapestOffer.itinerary.outbound,
      ...cheapestOffer.itinerary.homebound,
    ].map((leg) => ({
      iata: leg.operatingCarrier.code,
      number: leg.operatingCarrier.flightNumber,
    })),
    // We do not have price per outbound/return
    // Departure time & date are in the same value
  });
};

export const constructProductFromOffer = (
  offer: OfferFragment,
  currency: CurrencyCode,
  position = 1,
  currencyConversionRate: number,
) => ({
  productId: 'travel',
  price: offer.price,
  normalizedPrice: normalizeCurrency({
    currencyConversionRate,
    price: offer.price,
  }),
  currency,
  name: constructProductName(offer.itinerary),
  brand: [...offer.itinerary.outbound, ...offer.itinerary.homebound]
    .map((leg) => leg.marketingCarrier.code)
    .join('-'),
  category: null,
  position,
  url: offer.transferURL,
  variant:
    offer.itinerary.homebound.length === 0 ? Variant.ONEWAY : Variant.ROUNDTRIP,
});

const constructProductFromMbsOffer = (
  summary: Summary,
  currency: CurrencyCode,
  currencyConversionRate: number,
) => [
  constructMbsProduct({
    legGroupSummary: summary.leg_summaries,
    price: summary.total.amount,
    currency,
    currencyConversionRate,
  }),
  ...summary.leg_summaries.map((leg) => ({
    price: leg.bundle.price.amount,
    normalizedPrice: normalizeCurrency({
      currencyConversionRate,
      price: leg.bundle.price.amount,
    }),
    productId: 'bundle',
    brand: leg.bundle.code,
    name: leg.bundle.code,
    currency,
    category: null,
    variant: null,
    position: null,
    quantity: 1,
  })),
  ...summary.leg_summaries.reduce<Products[]>(
    (acc, leg) => [
      ...acc,
      ...leg.additional_services.map((additionalService) => ({
        price: additionalService.price.amount,
        normalizedPrice: normalizeCurrency({
          currencyConversionRate,
          price: additionalService.price.amount,
        }),
        productId: additionalService.service_class,
        brand: additionalService.service_class,
        name: additionalService.service_class,
        currency,
        category: null,
        variant: null,
        position: null,
        quantity: additionalService.quantity,
      })),
      ...leg.included_services.map((includedService) => ({
        price: 0,
        normalizedPrice: 0,
        productId: includedService.service_class,
        brand: includedService.service_class,
        name: includedService.service_class,
        currency,
        category: null,
        variant: null,
        position: null,
        quantity: includedService.quantity,
      })),
    ],
    [],
  ),
];

export const sendProductClickedEvent = ({
  currency,
  currencyConversionRate,
  journeyType = 'combined',
  offer,
  offerTags,
  position,
}: {
  currency: CurrencyCode;
  currencyConversionRate: number;
  journeyType?: SearchTypeValueType;
  offer: OfferFragment;
  offerTags: string[];
  position: number;
}) => {
  const origin =
    offer.itinerary.outbound[0]?.origin.code ||
    offer.itinerary.homebound[offer.itinerary.homebound.length - 1]?.destination
      .code ||
    '';

  const destination =
    offer.itinerary.outbound[offer.itinerary.outbound.length - 1]?.destination
      .code ||
    offer.itinerary.homebound[0]?.origin.code ||
    '';

  productClicked({
    ...constructProductFromOffer(
      offer,
      currency,
      position,
      currencyConversionRate,
    ),
    offerTags,
    searchType: journeyType,
    origin,
    destination,
    od: `${origin}-${destination}`,
    // eslint-disable-next-line functional/immutable-data
    carriers: getIatasFromOffers([offer]).sort().join('-'),
  });
};

export const constructMbsProduct = ({
  currency,
  currencyConversionRate,
  legGroupSummary,
  price,
}: {
  currency: CurrencyCode;
  currencyConversionRate: number;
  legGroupSummary: LegGroupSummary[];
  price: number;
}) => ({
  productId: 'travel',
  price,
  normalizedPrice: normalizeCurrency({ currencyConversionRate, price }),
  currency,
  name: constructMbsProductName(legGroupSummary),
  brand: legGroupSummary
    .flatMap((legGroupSummary) =>
      legGroupSummary.legs.map((leg) => leg.marketing_carrier.code),
    )
    .join('-'),
  category: null,
  variant: legGroupSummary.every((legGroupSummary) =>
    legGroupSummary.legs.every((leg) => leg.is_outbound),
  )
    ? Variant.ONEWAY
    : Variant.ROUNDTRIP,
  position: 1,
  url: window.location.href,
  quantity: 1,
});

export const sendProductViewedEvent = ({
  bundleCode,
  currency,
  currencyConversionRate,
  loadTime,
  offerId,
  summary,
}: {
  bundleCode: string;
  currency: CurrencyCode;
  currencyConversionRate: number;
  loadTime: string;
  offerId: string;
  summary: Summary;
}) => {
  const legGroupSummary = summary.leg_summaries;
  const price = summary.total.amount;
  const dohopService = getDohopServiceFromSummary(summary);

  const mbsProduct = constructMbsProduct({
    legGroupSummary,
    currency,
    price,
    currencyConversionRate,
  });

  pushToDataLayer({
    price,
    currency,
    orderId: offerId,
    bundleCode,
    brand: mbsProduct.brand,
  });

  const [outbound] = partition(
    (leg) => leg.is_outbound,
    legGroupSummary.flatMap((legGroupSummary) => legGroupSummary.legs),
  );

  const origin = outbound[0]?.origin?.airport_iata ?? '';
  const destination =
    outbound[outbound.length - 1]?.destination?.airport_iata ?? '';

  productViewed({
    ...mbsProduct,
    loadTime,
    normalizedFee: normalizeCurrency({
      currencyConversionRate,
      price: dohopService?.price.amount || 0,
    }),
    normalizedPrice: normalizeCurrency({ currencyConversionRate, price }),
    origin,
    destination,
    od: `${origin}-${destination}`,
    // eslint-disable-next-line functional/immutable-data
    carriers: getIatasFromSummary({ leg_summaries: legGroupSummary })
      .sort()
      .join('-'),
  });
};

export const sendProductListViewedEvent = ({
  cheapestProductPrice,
  currency,
  currencyConversionRate,
  expectedCheapestProductPrice,
  journeyType = 'combined',
  loadTime,
  offers,
  totalAdult,
  totalChild,
  totalInfant,
}: {
  cheapestProductPrice: number;
  currency: CurrencyCode;
  currencyConversionRate: number;
  expectedCheapestProductPrice?: number;
  journeyType?: SearchTypeValueType;
  loadTime: string;
  offers: OfferFragment[];
  totalAdult: number;
  totalChild: number;
  totalInfant: number;
}) => {
  const [offer] = offers;
  const origin =
    offer?.itinerary.outbound[0]?.origin.code ||
    offer?.itinerary.homebound[offer.itinerary.homebound.length - 1]
      ?.destination.code ||
    '';

  const destination =
    offer?.itinerary.outbound[offer.itinerary.outbound.length - 1]?.destination
      .code ||
    offer?.itinerary.homebound[0]?.origin.code ||
    '';

  productListViewed({
    loadTime,
    totalAdult,
    totalChild,
    totalInfant,
    products: offers.map((offer, index) => ({
      ...constructProductFromOffer(
        offer,
        currency,
        index + 1,
        currencyConversionRate,
      ),
      quantity: 1,
    })),
    searchType: journeyType,
    origin,
    destination,
    od: `${origin}-${destination}`,
    // eslint-disable-next-line functional/immutable-data
    carriers: getIatasFromOffers(offers).sort().join('-'),
    expectedCheapestProductPrice,
    cheapestProductPrice,
  });
};

export const sendNoSearchResultEvent = emptySearchResults;

export const sendPriceMismatchEvent = ({
  currency,
  currencyConversionRate,
  isSwallow,
  newPrice,
  oldPrice,
}: {
  currency: CurrencyCode;
  currencyConversionRate: number;
  isSwallow: boolean;
  newPrice: number;
  oldPrice: number;
}) =>
  priceMismatch({
    currency,
    oldPrice,
    newPrice,
    isSwallow,
    oldPriceNormalized: normalizeCurrency({
      currencyConversionRate,
      price: oldPrice,
    }),
    newPriceNormalized: normalizeCurrency({
      currencyConversionRate,
      price: newPrice,
    }),
  });

export const sendAddServiceEvent = ({
  currencyConversionRate,
  offer,
  quantity,
  serviceId,
}: {
  currencyConversionRate: number;
  offer: OfferResponse;
  quantity: number;
  serviceId: string;
}) => {
  const service = getServiceFromServiceId(serviceId, offer.service_groups);

  if (service) {
    addService({
      quantity,
      productId: service.service_class,
      weight: 'weight' in service ? service.weight : undefined,
      normalizedPrice: normalizeCurrency({
        currencyConversionRate,
        price: service.price.amount,
      }),
    });

    return;
  }

  const paxService = offer.all_pax_services.find(
    (paxService) => paxService.service_id === serviceId,
  );

  if (paxService) {
    addService({
      quantity: paxService.quantity,
      productId: paxService.service_class,
      weight: undefined,
      normalizedPrice: normalizeCurrency({
        currencyConversionRate,
        price: paxService.price.amount,
      }),
    });
  }
};

export const sendServiceMismatchEvent = serviceMismatch;

export const sendUpdatePassengersEvent = (props: UpdatePassengersProperties) =>
  updatePassengers(props);

export const createRouteString = (route: RouteFragment[]) => {
  const stationsLegString = route
    .map((leg) => `${leg.origin.code},${leg.destination.code}`)
    .join(',');

  const departureArrivalString = route
    .map(
      (leg) =>
        `${dayjs(leg.departure).toISOString()},${dayjs(leg.arrival).toISOString()}`,
    )
    .join(',');

  return `${stationsLegString},${departureArrivalString}`;
};

export const createLegString = (legs: Leg[]) => {
  const stationsLegString = legs
    .map((leg, index) => {
      if (index === 0) {
        return leg.origin.airport_iata;
      } else if (index === legs.length - 1) {
        return leg.destination.airport_iata;
      }

      return '';
    })
    .join(',');

  const departureArrivalString = legs
    .map((leg, index) => {
      if (index === 0) {
        return dayjs(leg.departure).toISOString();
      } else if (index === legs.length - 1) {
        return dayjs(leg.arrival).toISOString();
      }

      return '';
    })
    .join(',');

  return `${stationsLegString},${departureArrivalString}`;
};

export const sendOrderCompletedEvent = ({
  bookings,
  currency,
  currencyConversionRate,
  itinerary,
  locale,
  orderId,
  partner,
  passengers,
  paymentMethod,
  premiumTierName,
  summary,
}: {
  bookings: OrderSummaryResponseBookingsItem[];
  currency: CurrencyCode;
  currencyConversionRate: number;
  itinerary: SegmentedItineraryWithCoveredConnections;
  locale: string;
  orderId: string;
  partner: Partner;
  passengers: Passenger[];
  paymentMethod?: string;
  premiumTierName: string;
  summary: Summary;
}) => {
  const totalAdult = passengers.filter(
    (pax) => pax.expected_type === PaxType.a,
  ).length;

  const totalChild = passengers.filter(
    (pax) => pax.expected_type === PaxType.c,
  ).length;

  const totalInfant = passengers.filter(
    (pax) => pax.expected_type === PaxType.i,
  ).length;

  const origin = itinerary.outbound[0]?.legs[0]?.origin.airport_iata ?? '';
  const destination =
    itinerary.outbound[itinerary.outbound.length - 1]?.legs[0]?.destination
      .airport_iata ?? '';

  const dohopService = getDohopServiceFromSummary(summary);

  const bookingStatuses = bookings.map((booking) => ({
    bookingId: booking.booking_id,
    status: booking.status,
  }));

  const { cabinBagsSummary, checkedBagsSummary } = getLuggageSummaryFromSummary(
    summary,
    totalAdult + totalChild,
    itinerary.outbound.length + itinerary.homebound.length,
    currencyConversionRate,
  );

  pushToDataLayer({
    totalAdult,
    totalChild,
    totalInfant,
    paymentMethod,
    variant:
      itinerary.homebound.length === 0 ? Variant.ONEWAY : Variant.ROUNDTRIP,
    legString: `${createLegString(itinerary.outbound.flatMap((bound) => bound.legs))}${
      itinerary.homebound.length > 0
        ? `|${createLegString(itinerary.homebound.flatMap((bound) => bound.legs))}`
        : ''
    }`,
    brand: [
      ...(itinerary.outbound[0]?.legs ?? []),
      ...(itinerary.homebound[0]?.legs ?? []),
    ]
      .map((leg) => leg.marketing_carrier.code)
      .join('|'),
    price: summary.total.amount,
    currency,
    orderId,
  });

  orderCompleted({
    orderId,
    total: summary.total.amount,
    normalizedFee: normalizeCurrency({
      currencyConversionRate,
      price: dohopService?.price.amount || 0,
    }),
    normalizedPrice: normalizeCurrency({
      currencyConversionRate,
      price: summary.total.amount,
    }),

    ancillaries: {
      cabinBagsSummary,
      checkedBagsSummary,
    },
    bookingStatuses,
    overallOrderStatus: getOverallOrderStatus(bookingStatuses),
    currency,
    totalAdult,
    totalChild,
    totalInfant,
    origin,
    destination,
    od: `${origin}-${destination}`,
    carriers: [
      ...new Set(
        [
          ...itinerary.outbound.flatMap((bound) => bound.legs),
          ...itinerary.homebound.flatMap((bound) => bound.legs),
        ].map((leg) => leg.marketing_carrier.code),
      ),
    ]
      .sort()
      .join('-'),
    departureDate: toUTCLocaleString({
      date: dayjs(itinerary.outbound[0]?.legs[0]?.departure).toDate(),
      locale: Language.English,
      residency: 'UK',
      options: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        hourCycle: 'h23',
      },
    }),
    returnDate:
      itinerary.homebound.length > 0
        ? toUTCLocaleString({
            date: dayjs(itinerary.homebound[0]?.legs[0]?.departure).toDate(),
            locale: Language.English,
            residency: 'UK',
            options: {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              weekday: 'long',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
              hourCycle: 'h23',
            },
          })
        : null,
    language: locale,
    paymentMethod: paymentMethod || 'unknown',
    partner,
    products: [
      ...bookings.flatMap((booking) =>
        booking.booking_type === 'travel'
          ? []
          : [
              {
                productId: booking.booking_type || 'service',
                price: booking.total.amount,
                normalizedPrice: normalizeCurrency({
                  price: booking.total.amount,
                  currencyConversionRate,
                }),
                currency: booking.total.currency,
                name: premiumTierName,
                brand: premiumTierName,
                category: null,
                variant: null,
                position: null,
                quantity: 1,
              },
            ],
      ),

      ...constructProductFromMbsOffer(
        summary,
        currency,
        currencyConversionRate,
      ),
    ],
  });

  summary.leg_summaries.forEach((leg) =>
    leg.additional_services.forEach((additionalService) =>
      additionalServicePurchase({
        price: additionalService.price.amount,
        productId: additionalService.service_class,
        currency,
        quantity: additionalService.quantity,
        normalizedPrice: normalizeCurrency({
          price: additionalService.price.amount,
          currencyConversionRate,
        }),
      }),
    ),
  );

  summary.other_services.forEach((otherService) =>
    additionalServicePurchase({
      price: otherService.price.amount,
      productId: otherService.service_class,
      currency,
      quantity: otherService.quantity,
      normalizedPrice: normalizeCurrency({
        price: otherService.price.amount,
        currencyConversionRate,
      }),
    }),
  );
};

export const sendPaymentInfoEnteredEvent = ({
  checkoutId,
  currency,
  currencyConversionRate,
  offer,
  orderId,
  paymentMethod,
  total,
}: {
  checkoutId: string;
  currency: CurrencyCode;
  currencyConversionRate: number;
  offer: OfferResponse;
  orderId: string;
  paymentMethod: string;
  total: number;
}) =>
  paymentInfoEntered({
    checkoutId,
    orderId,
    paymentMethod,
    currency,
    total,
    products: constructProductFromMbsOffer(
      offer.summary,
      currency,
      currencyConversionRate,
    ),
  });

export const getExpectedCheapestProductPrice = ({
  arrivalDateFromAvailability,
  departureDateFromAvailability,
}: {
  arrivalDateFromAvailability?: AvailabilityNode | null;
  departureDateFromAvailability?: AvailabilityNode | null;
}) => {
  if (
    departureDateFromAvailability?.lowestFare &&
    arrivalDateFromAvailability?.lowestFare
  ) {
    return (
      departureDateFromAvailability.lowestFare +
      arrivalDateFromAvailability.lowestFare
    );
  }

  if (departureDateFromAvailability?.lowestFare) {
    return departureDateFromAvailability.lowestFare;
  }

  if (arrivalDateFromAvailability?.lowestFare) {
    return arrivalDateFromAvailability.lowestFare;
  }

  return undefined;
};

export const getCheapestProductPrice = ({
  homeboundPricePerPerson,
  journeyType,
  outboundPricePerPerson,
  price,
}: {
  homeboundPricePerPerson?: number | null;
  journeyType: SearchTypeValueType;
  outboundPricePerPerson?: number | null;
  price?: number | null;
}) => {
  if (journeyType === 'outbound' && outboundPricePerPerson) {
    return outboundPricePerPerson;
  }

  if (journeyType === 'inbound' && homeboundPricePerPerson) {
    return homeboundPricePerPerson;
  }

  return price || 0;
};

export const sendSearchResultAnalyticsEvent = ({
  activeFilters,
  activeSort,
  bestOffers,
  currency,
  currencyConversionRate,
  departureDate,
  departureDays,
  destinations,
  journeyType = 'combined',
  loadTime,
  offers,
  offersFilters,
  origins,
  paxTypeAges,
  residency,
  returnDate,
  returnDays,
}: {
  activeFilters?: OfferFilters | null;
  activeSort: Sort;
  bestOffers?: { [key in Sort]?: OfferFragment | null } | null;
  currency: CurrencyCode;
  currencyConversionRate: number;
  departureDate: Dayjs | null;
  departureDays: AvailabilityNode[];
  destinations: string[];
  journeyType?: SearchTypeValueType;
  loadTime: string;
  offers: OfferFragment[];
  offersFilters?: OfferFilters | null;
  origins: string[];
  paxTypeAges: {
    [paxType: string]: number[];
  };
  residency: string;
  returnDate: Dayjs | null;
  returnDays: AvailabilityNode[];
}) => {
  const departureDateFromAvailability = departureDays.find((availability) =>
    departureDate?.isSame(availability.date, 'day'),
  );

  const origin = origins[0] || '';
  const destination = destinations[0] || '';

  const arrivalDateFromAvailability = returnDays.find((availability) =>
    returnDate?.isSame(availability.date, 'day'),
  );

  if (offers.length === 0 || !offersFilters) {
    sendNoSearchResultEvent({
      loadTime,
      searchType: journeyType,
      hasAvailabilityMismatch: Boolean(
        departureDateFromAvailability?.date ||
          arrivalDateFromAvailability?.date,
      ),
      origin,
      destination,
      od: `${origin}-${destination}`,
    });

    return;
  }

  pushCheapestOfferToDatalayer({
    currency,
    residency,
    cheapestOffer: bestOffers?.CHEAPEST,
  });

  if (activeFilters === null || isDeepEqual(activeFilters, offersFilters)) {
    const expectedCheapestProductPrice = getExpectedCheapestProductPrice({
      arrivalDateFromAvailability,
      departureDateFromAvailability,
    });

    const cheapestProductPrice = getCheapestProductPrice({
      journeyType,
      outboundPricePerPerson: bestOffers?.CHEAPEST?.outboundPricePerPerson,
      homeboundPricePerPerson: bestOffers?.CHEAPEST?.homeboundPricePerPerson,
      price: bestOffers?.CHEAPEST?.price,
    });

    sendProductListViewedEvent({
      loadTime,
      currency,
      offers,
      totalAdult: paxTypeAges[CMSPassengerType.ADULT]?.length || 0,
      totalChild: paxTypeAges[CMSPassengerType.CHILD]?.length || 0,
      totalInfant: paxTypeAges[CMSPassengerType.INFANT]?.length || 0,
      journeyType,
      currencyConversionRate,
      expectedCheapestProductPrice: expectedCheapestProductPrice
        ? normalizeCurrency({
            currencyConversionRate,
            price: expectedCheapestProductPrice,
          })
        : undefined,
      cheapestProductPrice: normalizeCurrency({
        currencyConversionRate,
        price: cheapestProductPrice,
      }),
    });

    return;
  }

  sendSearchResultFilterEvent({
    offers,
    currency,
    activeFilters: offersFilters,
    activeSort,
    journeyType,
    currencyConversionRate,
  });
};

export const sendBundleSelectedEvent = ({
  bundleId,
  currencyConversionRate,
  loadTime,
  offer,
}: {
  bundleId: string;
  currencyConversionRate: number;
  loadTime: string;
  offer: OfferResponse;
}) => {
  const bundle = offer.bundle_groups.reduce<OfferBundle | undefined>(
    (name, bundleGroup) => {
      if (name) {
        return name;
      }

      return bundleGroup.bundles.find(
        (bundle) => bundle.bundle_id === bundleId,
      );
    },
    undefined,
  );

  const dohopService = getDohopServiceFromSummary(offer.summary);

  if (bundle) {
    bundleSelected({
      bundleName: bundle.bundle_codes[0] || '',
      loadTime,
      normalizedFee: normalizeCurrency({
        currencyConversionRate,
        price: dohopService?.price.amount || 0,
      }),
      normalizedPrice: normalizeCurrency({
        currencyConversionRate,
        price: bundle.price.amount,
      }),
    });
  }
};

export const addUserProperties = ({
  currency,
  experiments = [],
  locale,
  partner,
  residency,
}: {
  currency: CurrencyCode;
  experiments?: Experiment[];
  locale: Language;
  partner: Partner;
  residency: string;
}) => {
  return () => {
    window.rudderanalytics?.identify(
      {
        partner,
        originalReferrer: document.referrer,
        isMobile: 'ontouchstart' in document.documentElement,
        currency,
        residency,
        locale,
        ...experiments.reduce(
          (experimentUserProperties, experiment) => ({
            ...experimentUserProperties,
            [experiment.name]: experiment.variant,
          }),
          {},
        ),
      },
      createConsentedDestinations(),
    );
    experiments.forEach((experiment) => {
      window.rudderanalytics?.track('$experiment_started', {
        'Experiment name': experiment.name,
        'Variant name': experiment.variant,
      });
    });
  };
};

export const sendSearchResultFilterEvent = ({
  activeFilters,
  activeSort,
  currency,
  currencyConversionRate,
  journeyType = 'combined',
  offers,
}: {
  activeFilters?: OfferFilters | null;
  activeSort: Sort;
  currency: CurrencyCode;
  currencyConversionRate: number;
  journeyType?: SearchTypeValueType;
  offers: OfferFragment[];
}) => {
  productListFiltered({
    filters: activeFilters
      ? Object.keys(activeFilters).map((filter) => ({
          type: filter,
          value: activeFilters[filter as keyof typeof activeFilters] as string,
        }))
      : [],
    sorts: Object.values(Sort).map((sort) => ({
      type: sort as string,
      value: sort === activeSort ? 'true' : 'false',
    })),
    products: offers.map((offer, index) => ({
      ...constructProductFromOffer(
        offer,
        currency,
        index + 1,
        currencyConversionRate,
      ),
      quantity: 1,
    })),
    searchType: journeyType,
  });
};

export const getCreateOrderErrorType = (error_code: string) =>
  error_code.split('.')[0] ?? '';

export const sendCreateOrderErrorEvent = ({
  error_code,
  isRecoverable,
}: {
  error_code: string;
  isRecoverable: boolean;
}) => {
  const error_type = getCreateOrderErrorType(error_code);

  createOrderError({
    errorType: error_type,
    isRecoverable,
  });
};
