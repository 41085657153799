import { useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { zIndices } from '@ui-v2/theme/zIndices';
import { useBaseAnalytics } from '@ui-v2/utils/contexts/BaseAnalyticsContext';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { fadeIn } from '../animations/animations';
import Box from '../Box/Box';
import Text from '../Text/Text';

// Animating the container in obscures a jumping arrow bug we are dealing with in the tooltip
const StyledArrowContainer = styled(ArrowContainer)`
  animation: ${fadeIn} 150ms;
`;

export interface Props {
  children: React.ReactNode;
  id: string;
  isDisabled?: boolean;
  text: React.ReactNode;
  trackingId?: string;
}

const Tooltip = ({ children, id, text, trackingId }: Props) => {
  const { colours } = useTheme();
  const [showTooltip, toggleShowTooltip] = useState(false);
  const { sendTooltipShownEvent } = useBaseAnalytics();

  const setShowTooltip = () => {
    toggleShowTooltip(true);
    sendTooltipShownEvent({ id: trackingId ? trackingId : id });
  };

  const setHideTooltip = () => toggleShowTooltip(false);

  return (
    <Popover
      containerStyle={{ zIndex: zIndices.tooltips.toString() }}
      content={({ childRect, popoverRect, position }) => (
        <StyledArrowContainer
          aria-hidden={!showTooltip}
          arrowColor={colours.components.tooltip.surface}
          arrowSize={6}
          childRect={childRect}
          popoverRect={popoverRect}
          position={position}
        >
          <Box
            bg="components.tooltip.surface"
            borderRadius="cardSecondary"
            colour="components.tooltip.text"
            css={{
              padding: 10,
            }}
            id={id}
            maxWidthCustom={350}
            textAlign="center"
          >
            {typeof text === 'string' ? (
              <Text as="div" variant="heading-7">
                {text}
              </Text>
            ) : (
              text
            )}
          </Box>
        </StyledArrowContainer>
      )}
      isOpen={showTooltip}
      positions={['top', 'bottom', 'left', 'right']}
    >
      <span
        aria-describedby={id}
        onBlur={setHideTooltip}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onFocus={setShowTooltip}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        onMouseEnter={setShowTooltip}
        onMouseLeave={setHideTooltip}
        role="button"
        tabIndex={-1}
      >
        {children}
      </span>
    </Popover>
  );
};

const TooltipWrapper = (props: Props) => {
  if (props.isDisabled) {
    return props.children;
  }

  return <Tooltip {...props} />;
};

export default TooltipWrapper;
