import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  IconColourProp,
  TextColourProp,
  ThemeMarginProps,
} from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { createTypography, hexToRGBA, mqMax } from '@ui-v2/utils/styleUtils';
import { buildMargin } from '@ui-v2/utils/themePropBuilders';
import Box from '../../Box/Box';
import Icon, { IconProps } from '../../Icon/Icon';

export type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > &
    Pick<ThemeMarginProps, 'mt' | 'mb'> & {
      errorMessage?: string;
      iconLeft?: IconProps['type'];
      iconRight?: IconProps['type'];
      isLoading?: boolean;
      label?: string;
      name: string;
      overriddenActiveState?: boolean;
      overriddenFocusState?: boolean;
    },
  'ref'
>;

/**
 * Rule: margin-bottom: 2px is there to account for the 2px wide outline which
 * is not recognized within the box-sizing system
 */
export const StyledInput = styled('input')<
  Omit<InputProps, 'label' | 'iconLeft' | 'iconRight' | 'warningIcon'> & {
    hasIconLeft: boolean;
    hasIconRight: boolean;
    state: InputState;
  }
>(({ hasIconLeft, hasIconRight, state, theme }) => [
  createTypography(theme.typography.body01),
  css`
    display: block;
    width: 100%;
    padding: ${theme.spacings[12]}px ${theme.spacings[16]}px;
    border: none;
    border-radius: ${theme.shape.input}px;
    margin-right: ${theme.spacings[2]}px;
    margin-bottom: ${theme.spacings[2]}px;
    color: ${theme.colours.text.default};
    background-color: ${theme.colours.surface.default};
    outline: 1px solid ${theme.colours.border.default};
    transition:
      color ${TRANSITION_SPEED},
      outline-color ${TRANSITION_SPEED},
      padding-right ${TRANSITION_SPEED};

    &:hover {
      outline: 1px solid ${theme.colours.border.interactive};
    }

    &:active,
    &:focus {
      outline: 2px solid ${theme.colours.border.interactive};
    }

    ${mqMax[768]} {
      padding: ${theme.spacings[12]}px ${theme.spacings[8]}px;
    }
  `,
  state === 'error' &&
    css`
      outline: 2px solid ${theme.colours.border.critical};
    `,
  state === 'disabled' &&
    css`
      color: ${theme.colours.text.disabled};
      outline-color: ${hexToRGBA(theme.colours.border.default, 0.5)};

      &:hover {
        outline-color: ${hexToRGBA(theme.colours.border.default, 0.8)};
      }
    `,
  hasIconLeft &&
    css`
      padding-left: ${theme.spacings[48]}px;

      ${mqMax[768]} {
        padding-left: ${theme.spacings[40]}px;
      }
    `,
  hasIconRight &&
    css`
      padding-right: ${theme.spacings[48]}px;

      ${mqMax[768]} {
        padding-right: ${theme.spacings[40]}px;
      }
    `,
]);

export const TRANSITION_SPEED = '200ms';

export type InputState = 'default' | 'error' | 'disabled';

export type InputVariant = 'default' | 'floating-label';

export const textColourMapper: Record<InputState, TextColourProp> = {
  default: 'text.default',
  error: 'text.critical',
  disabled: 'text.disabled',
};

export const iconColourMapper: Record<InputState, IconColourProp> = {
  default: 'icons.default',
  error: 'icons.on.critical',
  disabled: 'icons.disabled',
};

export const StyledWrapper = styled('div')<ThemeMarginProps>(
  ({ theme, ...props }) => [
    css(
      buildResponsiveValues({
        ...buildMargin(props),
      }),
    ),
  ],
);

const StyledIconBox = styled(Box)`
  transition: top 200ms;
`;

export const InputIconLeft = ({
  isFloatingVariantActive,
  state,
  type,
  variant,
}: {
  isFloatingVariantActive?: boolean;
  state: InputState;
  type: IconProps['type'];
  variant?: InputVariant;
}) => (
  <StyledIconBox
    alignItems="center"
    display="flex"
    height="100%"
    left={[12, 12, 16]}
    pointerEvents="none"
    position="absolute"
    top={variant === 'floating-label' && isFloatingVariantActive ? 8 : 0}
  >
    <Icon colour={iconColourMapper[state]} size={20} type={type} />
  </StyledIconBox>
);

export const InputIconRight = ({
  isFloatingVariantActive,
  state,
  type,
  variant,
}: {
  isFloatingVariantActive?: boolean;
  state: InputState;
  type: IconProps['type'];
  variant?: InputVariant;
}) => (
  <StyledIconBox
    alignItems="center"
    display="flex"
    height="100%"
    pointerEvents="none"
    position="absolute"
    right={[12, 12, 16]}
    top={variant === 'floating-label' && isFloatingVariantActive ? 6 : 0}
  >
    <Icon colour={iconColourMapper[state]} size={20} type={type} />
  </StyledIconBox>
);

export const StyledLoaderBox = styled(Box)`
  pointer-events: none;
`;
