import { css } from '@emotion/react';

const transaviaFonts = css`
  @font-face {
    font-display: fallback;
    font-family: Nexa;
    font-weight: 300;
    src: url('/fonts/Transavia/Nexa-Book.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: Nexa;
    font-weight: 800;
    src: url('/fonts/Transavia/Nexa-ExtraBold.woff2');
  }
`;

export default transaviaFonts;
