import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Container from '@ui-v2/core/Container/Container';

export interface Props {
  error: Maybe<Error>;
  showErrorMessage?: boolean;
  text: string;
}

const ErrorText = styled.div(
  ({ theme: { colours } }) => css`
    color: ${colours.text.critical};
    text-align: center;
  `,
);

const Wrapper = styled.div(
  ({ theme: { colours, spacings } }) => css`
    padding: ${spacings['16']}px;
    background: ${colours.surface.critical};
  `,
);

const DefaultErrorView = ({ error, showErrorMessage, text }: Props) => (
  <Wrapper>
    <Container>
      <ErrorText>{text}</ErrorText>
      {showErrorMessage && error && (
        <ErrorText>Error: {error.message}</ErrorText>
      )}
    </Container>
  </Wrapper>
);

export default DefaultErrorView;
