import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ThemeAnimationProps,
  ThemeBackgroundColourProps,
  ThemeBorderProps,
  ThemeCursorProps,
  ThemeDisplayProps,
  ThemeFlexboxProps,
  ThemeLayoutGridProps,
  ThemeLayoutProps,
  ThemeMarginProps,
  ThemeOpacityProps,
  ThemeOverflowProps,
  ThemePaddingProps,
  ThemePositionProps,
  ThemePrintStyleProps,
  ThemeTextColourProps,
  ThemeTextProps,
  ThemeVisibilityProps,
} from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { getUIDataProps } from '@ui-v2/utils/getUIDataProps';
import { shouldForwardThemeProp } from '@ui-v2/utils/styleUtils';
import {
  buildBackgroundColour,
  buildBorderProps,
  buildColour,
  buildDisplay,
  buildFlexProps,
  buildLayoutProps,
  buildMargin,
  buildOpacityProps,
  buildOverflowProps,
  buildPadding,
  buildCursorProps,
  buildPositionProps,
  buildTextProps,
  buildLayoutGridProps,
  buildVisibilityProps,
  buildPrintStyleProps,
} from '@ui-v2/utils/themePropBuilders';
import { buildBoxAnimationProps } from './boxAnimations';

export type BoxProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
  'ref'
> &
  ThemeMarginProps &
  ThemePaddingProps &
  ThemeFlexboxProps &
  ThemePositionProps &
  ThemeLayoutProps &
  ThemeBorderProps &
  ThemeOverflowProps &
  ThemeTextProps &
  ThemeTextColourProps &
  ThemeBackgroundColourProps &
  ThemeOpacityProps &
  ThemeDisplayProps &
  ThemeVisibilityProps &
  ThemeCursorProps &
  ThemeAnimationProps &
  ThemeLayoutGridProps &
  ThemePrintStyleProps & {
    as?: 'div' | 'span' | 'button' | 'ul' | 'li';
    children?: React.ReactNode;
  };

const StyledBox = styled('div', {
  shouldForwardProp: shouldForwardThemeProp,
})<BoxProps>(({ theme, ...props }) => [
  css(
    buildResponsiveValues({
      ...buildDisplay(props),
      ...buildFlexProps(props),
      ...buildColour(props, theme),
      ...buildMargin(props),
      ...buildPadding(props),
      ...buildPositionProps(props, theme),
      ...buildLayoutProps(props, theme),
      ...buildBorderProps(props, theme),
      ...buildOverflowProps(props),
      ...buildTextProps(props),
      ...buildBackgroundColour(props, theme),
      ...buildOpacityProps(props),
      ...buildVisibilityProps(props),
      ...buildCursorProps(props),
      ...buildBoxAnimationProps(props),
      ...buildLayoutGridProps(props),
      ...buildPrintStyleProps(props),
    }),
  ),
]);

const Box = forwardRef<HTMLDivElement, BoxProps>((props, forwardedRef) => (
  <StyledBox {...props} ref={forwardedRef} {...getUIDataProps(props)} />
));

Box.displayName = 'Box';

export default Box;
