import { useEffect, MutableRefObject } from 'react';
import { KeyboardKey } from '@shared/types/enums';

const checkIfElementIsChildOfParent = (
  parentToFind: HTMLElement,
  child: HTMLElement | null,
): boolean => {
  if (!child) {
    return false; // We've reached the top of the tree without finding the target node
  }

  if (
    child.hasAttribute('id') &&
    child.getAttribute('id') === parentToFind.getAttribute('id')
  ) {
    return true; // We've found the target node!
  }

  return checkIfElementIsChildOfParent(parentToFind, child.parentElement); // Recursively traverse parent nodes
};

const useOnOutsideClick = (
  ref: MutableRefObject<HTMLElement | null>,
  handler?: (event: Event) => void,
  triggerRef?: MutableRefObject<Element | null>,
) => {
  useEffect(() => {
    if (!ref.current || !handler) {
      return undefined;
    }

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === KeyboardKey.Escape) {
        handler(event);
      }
    };

    const listener = (event: Event) => {
      const { target } = event;
      const element = ref.current;

      if (triggerRef) {
        const triggerElement = triggerRef.current;

        if (
          triggerElement &&
          target instanceof Node &&
          triggerElement.contains(target)
        ) {
          return;
        }
      }

      // Do nothing if clicking ref's element or descendent elements
      if (
        (element && target instanceof Node && element.contains(target)) ||
        (target &&
          element &&
          checkIfElementIsChildOfParent(element, target as HTMLElement)) ||
        element === null
      ) {
        return;
      }

      // Checks if the element is visible, if not we don't want to trigger handler
      if (element.offsetWidth <= 0 && element.offsetHeight <= 0) {
        return;
      }

      handler(event);

      return;
    };

    setTimeout(() => {
      window.addEventListener('keydown', onKeyDown);
      window.addEventListener('mousedown', listener);
    }, 0);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('mousedown', listener);
    };
  }, [handler, ref, triggerRef]);
};

export default useOnOutsideClick;
