import { captureMessage } from '@sentry/nextjs';
import { TranslationQuery } from 'next-translate';
// eslint-disable-next-line no-restricted-imports
import useTranslation from 'next-translate/useTranslation';

const useCmsTranslation = () => {
  const { t } = useTranslation();

  const translateCmsString = (
    idString: string,
    defaultString: string,
    query?: TranslationQuery | null,
  ) => {
    const translatedString = t(idString, query, { default: defaultString });

    // If the translation is missing, we want to capture an error and return the default value
    if (translatedString === '<replace_me>') {
      captureMessage(`Missing translation for ${idString}`);

      return defaultString;
    }

    return t(idString, query, { default: defaultString });
  };

  return { t: translateCmsString };
};

export type TranslateCmsString = ReturnType<typeof useCmsTranslation>['t'];

export default useCmsTranslation;
