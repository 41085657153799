import { css } from '@emotion/react';

const voloteaFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'Trade Gothic Bold Condensed No. 20';
    src: url('/fonts/Volotea/Trade-Gothic-Bold-Condensed-No.-20.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Trade Gothic LT Std';
    src: url('/fonts/Volotea/Trade-Gothic-LT-Std-Regular.woff2');
  }
`;

export default voloteaFonts;
