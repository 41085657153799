import dayjs, { extend as extendDayjs } from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { v4 as uuidv4 } from 'uuid';
import {
  Itinerary as GatewayItinerary,
  Leg as GatewayLeg,
  Route,
} from '@codegen/gatewayUtils';
import {
  CoveredConnection,
  Leg as mbsLeg,
  Segment,
  SegmentedItineraryWithCoveredConnections,
} from '@codegen/offerAPI';

extendDayjs(utc);
extendDayjs(durationPlugin);

const createLegObject = (mbsLeg: mbsLeg): GatewayLeg => {
  const duration = mbsLeg.duration ?? '0';

  return {
    arrival: dayjs.utc(mbsLeg.arrival),
    departure: dayjs.utc(mbsLeg.departure),
    destination: {
      name: mbsLeg.destination.airport_name,
      city: mbsLeg.destination.city_name,
      code: mbsLeg.destination.airport_iata,
      country: mbsLeg.destination.country_name,
    },
    origin: {
      name: mbsLeg.origin.airport_name,
      city: mbsLeg.origin.city_name,
      code: mbsLeg.origin.airport_iata,
      country: mbsLeg.origin.country_name,
    },
    marketingCarrier: {
      name: mbsLeg.marketing_carrier.name,
      code: mbsLeg.marketing_carrier.code,
      flightNumber: mbsLeg.flight_number,
    },
    operatingCarrier: {
      name: mbsLeg.operating_carrier.name,
      code: mbsLeg.operating_carrier.code,
      flightNumber: mbsLeg.flight_number,
    },
    duration: dayjs.duration(duration).asSeconds(),
    id: uuidv4(),
  };
};

export const getIsConnectingLeg = ({
  coveredConnections,
  currentLeg,
}: {
  coveredConnections: CoveredConnection[];
  currentLeg: mbsLeg;
}): boolean => {
  return coveredConnections.some((connection) => {
    return connection.to_leg_id === currentLeg.leg_id;
  });
};

const createRoute = (leg: GatewayLeg): Route => {
  return {
    arrival: leg.arrival,
    departure: leg.departure,
    destination: leg.destination,
    duration: leg.duration,
    marketingCarrier: leg.marketingCarrier,
    operatingCarrier: leg.operatingCarrier,
    origin: leg.origin,
    legs: [leg],
    id: uuidv4(),
  };
};

export const parseItineraryLegs = ({
  coveredConnections,
  segments,
}: {
  coveredConnections: CoveredConnection[];
  segments: Segment[];
}): Route[] => {
  const allMbsLegs = segments.flatMap((segment) => segment.legs);

  const parsedItinerary = allMbsLegs.reduce<Route[]>(
    (acc, currentLeg, index) => {
      const legObject = createLegObject(currentLeg);

      // Start by creating a route
      const route = createRoute(legObject);

      if (index === 0) {
        return [route];
      }

      const isConnectingLeg = getIsConnectingLeg({
        coveredConnections,
        currentLeg,
      });

      const previousLeg = acc[index - 1] ?? route;

      if (isConnectingLeg) {
        return [...acc, route];
      }

      // if the current leg is not a connecting leg, add it to the previous route
      return [
        ...acc.slice(0, acc.length - 1),
        {
          ...previousLeg,
          legs: [...previousLeg.legs, legObject],
          arrival: legObject.arrival,
          destination: legObject.destination,
          duration: previousLeg.duration + legObject.duration,
        },
      ];
    },
    [],
  );

  return parsedItinerary;
};

export const parseMBSItinerary = ({
  covered_connections,
  homebound,
  outbound,
}: SegmentedItineraryWithCoveredConnections): GatewayItinerary => {
  return {
    outbound: parseItineraryLegs({
      segments: outbound,
      coveredConnections: covered_connections,
    }),
    homebound: parseItineraryLegs({
      segments: homebound,
      coveredConnections: covered_connections,
    }),
  };
};
