import { css } from '@emotion/react';

const vietJetAirFonts = css`
  @font-face {
    font-display: fallback;
    font-family: KoHo;
    font-weight: 400;
    src: url('/fonts/VietJetAir/KoHo-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: KoHo;
    font-weight: 500;
    src: url('/fonts/VietJetAir/KoHo-Medium.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: KoHo;
    font-weight: 600;
    src: url('/fonts/VietJetAir/KoHo-SemiBold.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: KoHo;
    font-weight: 700;
    src: url('/fonts/VietJetAir/KoHo-Bold.woff2');
  }
`;

export default vietJetAirFonts;
