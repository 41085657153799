import { css } from '@emotion/react';

const skyteamFonts = css`
  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/SkyTeam/Roboto-Light.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/SkyTeam/Roboto-Regular.woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/SkyTeam/Roboto-Bold.woff2');
  }
`;

export default skyteamFonts;
