import useCmsTranslation from '@hooks/useCmsTranslation';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import Icon from '@ui-v2/core/Icon/Icon';
import Modal from '@ui-v2/core/Modal/Modal';
import Text from '@ui-v2/core/Text/Text';
import { getCarriersFromItinerary } from '@utils/itineraryUtils';
import useItinerary from '@web/context/hooks/useItinerary';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';
import { useStep } from '@web/context/StepContext';
import { createSearchUrlFromBookingDeeplink } from '@web/utils/booking/bookingUtils';
import useVendorPassengerRules from '../Booking/hooks/useVendorPassengerRules';

interface Props {
  isOpen: boolean;
}

const OfferErrorModal = ({ isOpen }: Props) => {
  const { t } = useCmsTranslation();
  const { query } = usePartnerRouter();
  const { steps } = useStep();
  const { itinerary } = useItinerary();
  const { vendorPassengerRules } = useVendorPassengerRules(
    getCarriersFromItinerary(itinerary),
  );

  const searchUrl = createSearchUrlFromBookingDeeplink(
    query,
    vendorPassengerRules,
    steps,
  );

  return (
    <Modal
      footer={
        <Box display="flex" justifyContent="flex-end">
          {/* Use a server routing link here to ensure a full refresh of the page */}
          <a href={searchUrl ?? '/'}>
            <Button isLoading={!searchUrl} size="small">
              {t('Search again', 'Search again')}
            </Button>
          </a>
        </Box>
      }
      id="offer-error-modal"
      isOpen={isOpen}
      maxWidth={532}
      showCloseButton={false}
      title={t(
        'journey_no_longer_available',
        'This journey is no longer available',
      )}
    >
      <Box display="flex" gap={16}>
        <Box flexShrink={0} height="auto">
          <Icon size={52} type="noFaresModalIcon" />
        </Box>
        <Text as="p" variant="body-1">
          {t(
            'no_fares_found_modal_description',
            "We've noticed some changes in the availability of one or more of your flights. Please try searching again to explore available options.",
          )}
        </Text>
      </Box>
    </Modal>
  );
};

export default OfferErrorModal;
